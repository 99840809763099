import React, { useState, useEffect } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import Button from '@mui/material/Button';
import useS3Operations from '../../hooks/useS3Operations';

function GlobalHelpModal({ style="icon", section = 'default', view='detail', modelData, title="help"}) {
  const [isOpen, setIsOpen] = useState(false);
  const [fetchVideo, setFetchVideo] = useState(false);
  const [specificTexts, setSpecificTexts] = useState([]);
  const [commonTexts, setCommonTexts] = useState([]);
  const [videosData, setVideosData] = useState([]);

  const s3Key = `cbbm_help_videos/help_videos_specific_${section}`;

  const { data: s3Data, loading: s3Loading } = useS3Operations({
    action: 'listObjects',
    key: s3Key,
    shouldExecute: fetchVideo,
    bucket: "cb-help-videos",
    nameEndFilter: ""
  });

  useEffect(() => {
    const fetchHelpTexts = async () => {
      try {
        // Fetch both specific and common help texts
        const [specificResponse, commonResponse] = await Promise.all([
          fetch(`${process.env.REACT_APP_GLOBAL_MAPPING_URL}help_texts/help_texts_specific_${section}.json`)
        ]);

        if (specificResponse.ok) {
          const specificData = await specificResponse.json();
          setSpecificTexts(specificData);
        } else {
          console.warn(`No specific help texts found for section ${section}`);
          setSpecificTexts([]);
        }

        if (commonResponse.ok) {
          const commonData = await commonResponse.json();
          setCommonTexts(commonData);
        } else {
          console.warn(`No common help texts found for view ${view}`);
          setCommonTexts([]);
        }
      } catch (error) {
        console.error("Failed to fetch help texts:", error);
      }
    };

    if (isOpen) {
      fetchHelpTexts();
    }
  }, [section, view, isOpen]);

  useEffect(() => {
    if (s3Data?.data) {
      const videos = s3Data.data
        .filter(item =>
          item.key.includes('help_videos_') &&
          item.key.includes(`_specific_${section}`)
        )
        .map(item => {
          const filename = item.key.split('/').pop();
          const isfrench = filename.includes('_fr');
          return {
            url: `${process.env.REACT_APP_S3_HELP_VIDEO}${item.key}`,
            title: filename
              .replace(`help_videos_specific_${section}`, '')
              .replace('_lang_fr', '')
              .replace('_lang_en', '')
              .replace('.mov', '')
              .replace('.mp4', '')
              .replace(/_/g, ' '),
            lang: isfrench ? 'FR' : 'EN',
            size: item.size,
            lastModified: new Date(item.lastModified).toLocaleDateString()
          };
        });

      // Sort videos alphabetically by title
      videos.sort((a, b) => a.title.localeCompare(b.title));

      setVideosData(videos);
    }
  }, [s3Data, section]);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const handleViewVideo = () => setFetchVideo(true);

  const renderHelpSection = (texts, title) => (
    <>
      {texts.length > 0 && (
        <>
          <Typography
            variant="h6"
            style={{
              textTransform: "capitalize",
              marginTop: '16px',
              marginBottom: '12px',
              color: '#666'
            }}
          >
            {title}
          </Typography>
          <div style={{ width: '100%', overflow: 'hidden' }}>
            {texts.map((item, index) => (
              <Accordion
                key={index}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}a-content`}
                  id={`panel${index}a-header`}
                >
                  <Typography style={{
                    wordWrap: 'break-word',
                    fontWeight: 500
                  }}>
                    {item.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails style={{
                  overflow: 'hidden',
                  padding: '16px'
                }}>
                  <Typography style={{
                    wordWrap: 'break-word',
                    lineHeight: '1.5'
                  }}>
                    {item.answer}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </>
      )}
    </>
  );

  return (
    <div>
      {style === "large" ? (
        <Button onClick={handleOpen} style={{width:"100%"}} variant="outlined">
          {title || "help"}
          <Tooltip title="Click for help texts and videos about this section">
            <InfoIcon sx={{cursor: 'help'}} color="primary" />
          </Tooltip>
        </Button>
      ) : (
        <Tooltip title="Click for help texts and videos about this section">
          <IconButton color="primary" onClick={handleOpen} size="small">
            <InfoIcon />
          </IconButton>
        </Tooltip>
      )}

      <Dialog
        open={isOpen}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle style={{ textTransform: "capitalize" }}>help | {section}</DialogTitle>
        <DialogContent>
          {renderHelpSection(commonTexts, `Common help for ${view}`)}
          {renderHelpSection(specificTexts, `Specific help for ${section}`)}

          <Button
            onClick={handleViewVideo}
            style={{width:"100%", marginTop: '20px'}}
            variant="outlined"
          >
            View Videos
            <Tooltip title="Click to view videos about this section">
              <InfoIcon sx={{cursor: 'help'}} color="primary" />
            </Tooltip>
          </Button>

          {fetchVideo && (
            <div className="mt-6">
              <h2 className="text-lg font-semibold mb-4">Help Videos</h2>

              <Accordion type="single" collapsible className="mb-4">
                <AccordionSummary>
                  <h4 className="text-sm font-medium">Message about videos</h4>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="space-y-4 text-sm text-muted-foreground">
                    <p>
                      These videos may be in English or French. While we hope any video in each language
                      is relevant to the corresponding section, there may be slight
                      differences between the English and French versions for the same
                      section.
                    </p>
                    <p>
                      As It is faster to make modifications and improvements on this app than to
                      redo an entire video. Therefore, some features may change. However,
                      the basic principles of this application will always be explained in
                      these videos.
                    </p>
                    <p>
                      We are a small company. Please do not expect professional-quality
                      videos. These videos are made for efficiency purposes, in a single
                      take, without editing. We prefer the information to be there, even
                      if it is not perfect, rather than non-existent.
                    </p>
                  </div>
                </AccordionDetails>
              </Accordion>

              {s3Loading ? (
                <div className="flex justify-center p-4">
                </div>
              ) : videosData.length > 0 ? (
                <div className="space-y-6">
                  {videosData.map((video, index) => (
                    <div key={index}>
                      <h3 style={{ textTransform: "capitalize" }} className="text-md font-medium mb-2">
                        {video.title} ({video.lang})
                      </h3>
                      <div className="relative aspect-video">
                        <video
                          className="absolute object-contain bg-black"
                          controls
                          preload="metadata"
                          style={{width: "100%"}}
                        >
                          <source src={video.url} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-sm text-muted-foreground">
                  No videos available for this section.
                </p>
              )}
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default GlobalHelpModal;
