import React, { useState, useEffect, useMemo } from "react";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { loadItems } from "../../api";

import SimpleTable from "../Table/SimpleTable";
import SelectableTable from "../Table/SelectableTable";
import SortableTable from "../Table/SortableTable";
import useModelLoader from "../../hooks/useModelLoader";
import HelpButton from '../Help/HelpButton'; 

function TableViewAsso({
  section,
  modelfile,
  association,
  queryParams,
  fromTable,
  inBetweenTable = "",
  handleRefresh,
  handleResults,
  custom_value="",
  custom_foreign_key="",
}) {
  const queryClient = useQueryClient();
  const [refreshCounter, setRefreshCounter] = useState(0);

  let fromModel = fromTable;
  let targetTable = section;
  let in_between_table = inBetweenTable;
  let junctionTable = section + "_" + fromTable;
  let junctionReverse = fromTable + "_" + section;
  let finalSection = section;

  if (custom_value) {
    console.log('custom_value',custom_value);
    fromModel = custom_value?.from_model;
    targetTable = custom_value?.table;
    junctionTable = custom_value?.junction_table;
    finalSection = custom_value?.table;
  }



  const { modelData } = useModelLoader(finalSection);

  let paramsToSend = "";

  if (association == "many_to_many_position") {
    paramsToSend = {
      table: section,
      from_id: queryParams.from_id,
      from_model: fromTable,
      query_type: "many_to_many_position",
      junction_table: fromTable + "_" + section,
    }; 
  } else if (association == "many_to_many") {
    paramsToSend = {
      table: section,
      from_id: queryParams.from_id,
      from_model: fromTable,
      query_type: "many_to_many",
      junction_table: fromTable + "_" + section,
    };
  } else if (association == "many_to_many_deep") {
    paramsToSend = {
      table: section,
      from_id: queryParams.from_id,
      from_model: fromTable,
      query_type: "many_to_many_deep",
      junction_table: section + "_" + fromTable,
      in_between_table: inBetweenTable,
    };
  } else if (association == "many_to_many_reverse") {
    paramsToSend = {
      table: targetTable,
      from_id: queryParams.from_id,
      from_model: fromModel,
      query_type: "many_to_many",
      junction_table: junctionTable,
    };
  } else if (association == "many_to_many_reverse_position") {
    paramsToSend = {
      table: targetTable,
      from_id: queryParams.from_id,
      from_model: fromModel,
      query_type: "many_to_many_position",
      junction_table: junctionTable,
    };
  } else if (association == "custom_specific") {
    console.log('custom called');
    paramsToSend = {
      table: targetTable,
      from_id: queryParams.from_id,
      custom_specific: custom_value.custom_specific
    };

  }  else if (custom_foreign_key != "") {
    console.log('custom_foreign_key called');
    paramsToSend = {...queryParams, custom_foreign_key: custom_foreign_key};

  }  else {
    paramsToSend = queryParams;
  }


  const getKeysOfFieldsToShow = (modelData) => {
    return modelData?.fields
      .filter((field) => field.showInTable)
      .map((field) => {
        return field.key;
      });
  };
  

  const fieldsToFetch = useMemo(
    () => getKeysOfFieldsToShow(modelData),
    [modelData]
  );


  const handleThisRefresh = () => {
    setRefreshCounter((prevCounter) => prevCounter + 1);
  };


  const queryKey = useMemo(() => ["items", section, paramsToSend, fieldsToFetch, refreshCounter], [
    section, paramsToSend, fieldsToFetch, refreshCounter
  ]);

  const query = useQuery({
    queryKey: queryKey,
    queryFn: () => loadItems(section, paramsToSend, fieldsToFetch)
  });
  const items = query.data || [];



  useEffect(() => {
    if (items && items.length > 0) {
      handleResults?.(true); 
    } else if (items && items.length === 0) {
      handleResults?.(false); 
    }
  }, [query.data]);


  if (items.message) {
    return (
      <div>{items.message}</div>
    );

  } let tableContent;

  if (association === "many_to_many_position" || association === "many_to_many_reverse_position" ) {
    tableContent = (
      <>
        {items.length > 0 && (
          <SortableTable
            data={items}
            section={finalSection}
            modeldata={modelData}
            fromTable={fromTable}
            fromId={queryParams.from_id}
            handleRefresh={handleRefresh}
            handleThisRefresh={handleThisRefresh}
          />
        )}
      </>
    );
  } else if (association === "many_to_many_deep") {
    tableContent = (
      <>
        {items.length > 0 && (
          <SimpleTable
            data={items}
            section={finalSection}
            modeldata={modelData}
            inBetweenTable="contacts"
            fromTable={fromTable}
            fromId={queryParams.from_id}
          />
        )}
      </>
    );
  } else if (association === "many_to_many_deep") {
    tableContent = (
      <>
        {items.length > 0 && (
          <SimpleTable
            data={items}
            section={finalSection}
            modeldata={modelData}
            inBetweenTable="contacts"
            fromTable={fromTable}
            fromId={queryParams.from_id}
          />
        )}
      </>
    );
  } else if (modelData?.tableAssoSelectable) {
    tableContent = (
      <>
        {items.length > 0 && (
         <SelectableTable
         data={items}
         section={finalSection}
         modeldata={modelData}
         fromTable={fromTable}
         fromId={queryParams.from_id}
         handleRefresh={handleRefresh}
         handleThisRefresh={handleThisRefresh}
         showGenerationButton={false}
       />
        )}
      </>
    );
  } else {
    tableContent = (
      <>
        {items.length > 0 && (
          <SimpleTable
            data={items}
            section={finalSection}
            modeldata={modelData}
            fromTable={fromTable}
            fromId={queryParams.from_id}
            handleRefresh={handleRefresh}
            handleThisRefresh={handleThisRefresh}
          />
        )}
      </>
    );
  }

  return (
    <div>
      {tableContent}
      <HelpButton helpData={section} helpTableDefault="common"/>
    </div>
  );
}

export default TableViewAsso;
