import React, { useState } from "react";
import "./Sidebar.css";
import menuData from "../../etc/MainMenu.json";
import { Link } from "react-router-dom";
import { Icon } from '@iconify/react';
import AddModal from "../Modal/AddModal";
import { ReactComponent as imgsDocs } from '../../icons/imgs_docs.svg';
import { ReactComponent as Attached } from '../../icons/attached.svg';
import { unslugify } from "../../utils/utils";
import { usePermissions } from '../../contexts/PermissionsContext';


const ICON_MAP = {
  Employees: 'clarity:employee-group-line',
  Financials: 'healthicons:money-bag-outline',
  Assets: 'ph:truck-thin',
  Buildings: 'ion:business-outline',
  Contacts: 'material-symbols:contact-mail-outline-sharp',
  Artworks: 'maki:art-gallery',
  Events : 'ic:outline-calendar-month',
  Content : 'fluent-mdl2:news',
  ImagesDocuments : 'tdesign:file-attachment',
  ConfigWheel : 'clarity:cog-line',
  Books: 'mdi:book-open-page-variant-outline',
  //... add other icons similarly
};



const Sidebar = () => {
  const [activeMenu, setActiveMenu] = useState(null);
  let hoverTimeout;  // Use to introduce delay for hiding off-canvas
  const { permissions } = usePermissions();

  const handleMouseEnter = (index) => {
    clearTimeout(hoverTimeout);  // Clear any previous timeout
    setActiveMenu(index);
  };

  const handleMouseLeave = () => {
    hoverTimeout = setTimeout(() => {
      setActiveMenu(null);
    }, 300);  // Delay of 300ms
  };


  //MODAL

  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [modalSection, setModalSection] = useState(null);

  const openAddModal = (section) => {
      setModalSection(section);
      setAddModalOpen(true);
  };

  const closeAddModal = () => {
    setModalSection(null);
    setAddModalOpen(false);
  };

  const accessibleMenuItems = menuData.menu_items.filter(item => 
    !item.permissions || permissions.includes(item.permissions)
  );

const canModify = (section) => permissions.includes(`can_modify_${section}`);
const canView = (section) => permissions.includes(`can_view_${section}`) || canModify(section);

return (
  <div className="sidebar-container">
    <div className="bg-white"></div>
    <div className="sidebar">
      {/* Top Level Home Icon */}
      <div>
      <Link to="/" className="sidebar-item">
          <Icon icon="line-md:home-simple" width="20" height="20" />
          </Link>
      </div>

      {/* Sidebar Items */}
      {menuData.menu_items.map((item, index) => {
          const showIcon = item.permission_needed_to_see_this_icon_link === false || permissions.includes(item.permission_needed_to_see_this_icon_link);

          if (!showIcon) {
            return null;
          }

          return (
            <div
              key={index}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
                <Link to="/" className="sidebar-item">
      <Icon icon={ICON_MAP[item.icon_name]} width="20" height="20" className="sidebar-icon" />
    </Link>

              <div className={`off-canvas ${activeMenu === index ? "active" : ""}`}>
                {Object.entries(item["off-canvas-content"]).map(([sectionKey, sectionValue]) => {
                  const showSubmenu = sectionValue.permission_needed_to_see_this_submenu === false || canView(sectionKey) || canView(sectionValue.permission_needed_to_see_this_submenu);

                  if (!showSubmenu) {
                    return null;
                  }

                  return (
                    <div key={sectionKey}>
                      <h3 className="section-title">{unslugify(sectionKey)}</h3>
                      <ul>
                        {Object.entries(sectionValue.submenu_items).map(([label, route]) => (
                          <li key={route}>
                            {route === 'addModal' ? (
                              <a href="#" onClick={() => openAddModal(sectionKey)}>{label}</a>
                            ) : label ==='TITLE' ? (route) :(
                              <Link to={route}>{unslugify(label)}</Link>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}

        <div className="sidebar-item-push"></div>
      </div>

      {modalSection != null && (
        <AddModal open={isAddModalOpen} handleClose={closeAddModal} section={modalSection} />
      )}
    </div>
  );
};

export default Sidebar;
