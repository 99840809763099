import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
  Box,
  IconButton,
  Card,
  CardContent
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Download as DownloadIcon, Close as CloseIcon } from '@mui/icons-material';

const ImagesModal = ({
  open,
  onClose,
  section,
  id,
  bucketPhotos,
  alt
}) => {
  const imageSizes = [
    { label: '50x40', suffix: '-50-40' },
    { label: '132x80', suffix: '-132-80' },
    { label: '300x200', suffix: '-300-200' },
    { label: '450x300', suffix: '-450-300' },
    { label: '900x600', suffix: '-900-600' },
    { label: '2000x1200', suffix: '-2000-1200' }
  ];

  const downloadImage = (suffix) => {
    const fullUrl = `https://${bucketPhotos}${process.env.REACT_APP_S3_REGION_URL}${section}/${id}/main_image/${alt}${suffix}.jpg`;
    const link = document.createElement('a');
    link.href = fullUrl;
    link.download = `${alt}${suffix}.jpg`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: 8 }}>
        Image Sizes
        <IconButton onClick={onClose}><CloseIcon /></IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <img
            src={`https://${bucketPhotos}${process.env.REACT_APP_S3_REGION_URL}${section}/${id}/main_image/${alt}-2000-1200.jpg`}
            alt={`${alt} - Large (2000x1200)`}
            style={{ maxWidth: '100%', maxHeight: 'calc(100vh - 130px)', borderRadius: 8 }}
          />
          <Typography variant="h6" sx={{ mt: 2 }}>2000x1200</Typography>
          <Button
            variant="contained"
            startIcon={<DownloadIcon />}
            sx={{ mt: 1 }}
            onClick={() => downloadImage('-2000-1200')}
          >
            Download Large Image
          </Button>
        </Box>

        <Grid container spacing={3} justifyContent="center" alignItems="stretch">
          {imageSizes.map((size) => (
           <Grid item size={{ xs: 4 }} sx={{ mb: 5 }} key={size.suffix}>
           <Card
             sx={{
               display: 'flex',
               flexDirection: 'column',
               alignItems: 'center',
               p: 2,
               height: '100%',
               mt: 10,
               border: '1px solid #eee', // Ajoute une bordure grise
               boxShadow: 'none', // Supprime l'élévation
               borderRadius: '4px', // Ajoute des coins arrondis pour une apparence élégante
             }}
           >
             <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flexGrow: 1, justifyContent: 'center' }}>
               {!size.fullWidth && (
                 <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 120, mb: 2 }}>
                   <img
                     src={`https://${bucketPhotos}${process.env.REACT_APP_S3_REGION_URL}${section}/${id}/main_image/${alt}${size.suffix}.jpg`}
                     alt={`${alt} - ${size.label}`}
                     style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                   />
                 </Box>
               )}
               <Typography variant="subtitle2" sx={{ mb: 1 }}>{size.label}</Typography>
             </CardContent>
             <Button
               variant="contained"
               startIcon={<DownloadIcon />}
               fullWidth
               sx={{ mt: 'auto' }}
               onClick={() => downloadImage(size.suffix)}
             >
               Download
             </Button>
           </Card>
         </Grid>
         
          ))}

    <Grid item size={{ xs: 12}} sx={{mt:10, mb:10}} key="hd">
    <Card
             sx={{
               display: 'flex',
               flexDirection: 'column',
               alignItems: 'center',
               p: 2,
               height: '100%',
               border: '1px solid #eee', // Ajoute une bordure grise
               boxShadow: 'none', // Supprime l'élévation
               borderRadius: '4px', // Ajoute des coins arrondis pour une apparence élégante
             }}
           >
                <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flexGrow: 1, justifyContent: 'center' }}>
    
        
                  <Typography variant="subtitle2" sx={{ mb: 1 }}>hd</Typography>
                </CardContent>
                <Button
                  variant="contained"
                  startIcon={<DownloadIcon />}
                  fullWidth
                  sx={{ mt: 'auto' }}
                  onClick={() => downloadImage("-hd")}
                >
                  Download
                </Button>
              </Card>
            </Grid> 
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ImagesModal;