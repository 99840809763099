import React, { useState } from 'react';
import { Button, Menu, MenuItem, Tooltip, Divider, Typography } from "@mui/material";
import modalConfig from './ModalConfig';
import ModalWithTrigger from './ModalWithTrigger';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddToAnythingMtmModal from "./AddToAnythingMtmModal"; // Import the correct components
import AddAnythingMtmOrFkModal from "./AddAnythingMtmOrFkModal"; // Import the correct components
import AddSalesModal from "./AddSalesModal";
import AddLocationModal from "./AddLocationModal";

const ModalButtonsAddTo = ({ buttonNames, onClickHandle, section, handleRefresh = null, selectedRowIds, limitToOne }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    if (typeof onClickHandle === 'function') {
      onClickHandle();
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Separate the buttonNames into two categories based on actual component match
  const existingRecordButtons = buttonNames.filter((buttonName) => {
    return modalConfig[buttonName]?.component === AddToAnythingMtmModal;
  });

  const newRecordButtons = buttonNames.filter((buttonName) => {
    return modalConfig[buttonName]?.component === AddAnythingMtmOrFkModal || modalConfig[buttonName]?.component === AddSalesModal || modalConfig[buttonName]?.component === AddLocationModal;
  });



  
  const renderModalItems = (buttonName) => {
    const ModalComponent = modalConfig[buttonName]?.component;
    const label = modalConfig[buttonName]?.label;
    const tooltipText = modalConfig[buttonName]?.tooltip || `Open ${label}`;



    /*
    <Tooltip key={buttonName} title={tooltipText} placement="right" enterDelay={1000} arrow>
      </Tooltip>
    */

    return (
  
        <MenuItem onClick={handleClose}>
          {ModalComponent && (
            <ModalWithTrigger
              type={buttonName}
              label={label}
              section={section}
              selectedRowIds={selectedRowIds}
              handleRefresh={handleRefresh}
              modalConfig={modalConfig}
              limitToOne={limitToOne}
            />
          )}
        </MenuItem>
    );
  };

  return (
    <div>
      <Tooltip
        title="Here you can add to an existing record or create a new record"
        enterDelay={1000}
        arrow
      >
        <Button
          sx={{
            width: '100%',
            color: 'white',
            backgroundColor: 'primary.main',
          }}
          size="small"
          aria-controls="add-to-menu"
          aria-haspopup="true"
          endIcon={<ArrowDropDownIcon />}
          onClick={handleClick}
        >
          Add
        </Button>
      </Tooltip>

      <Menu id="add-to-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        {existingRecordButtons.length > 0 && (
          <>
            <Typography variant="h6" sx={{ px: 2, pt: 1 }}>
              Add to Existing Record
            </Typography>
            {existingRecordButtons.map(renderModalItems)}
            {newRecordButtons.length > 0 && <Divider sx={{ my: 1 }} />}
          </>
        )}

        {newRecordButtons.length > 0 && (
          <>
            <Typography variant="h6" sx={{ px: 2, pt: 1 }}>
              Add a New Record and Add to It
            </Typography>
            {newRecordButtons.map(renderModalItems)}
          </>
        )}
      </Menu>
    </div>
  );
};

export default ModalButtonsAddTo;