
import MakeArtworksLabelsModal from "./Forms/MakeArtworksLabelsFormModal";
import MakeGlobalPdfFormModal from "./Forms/MakeGlobalPdfFormModal";
import MakeSpecificArtworksPdfFormModal from "./Forms/MakeSpecificArtworksPdfFormModal";
import MakeProformaModal from "./Forms/MakeProformaModal";
import MakeReportFormModal from "./Forms/MakeReportFormModal";
import MakeContactsLabelsModal from "./Forms/MakeContactsLabelsFormModal";


export const modalStyles = {
  regular: {
    position: "absolute",
    top: "5vw",
    left: "5vw",
    display: "flex",
    width: "87vw", 
    height: "80vh", 
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 10,
    overflow: 'auto',
    p: 4
  },
  large: {
    position: "absolute",
    top: "20%",
    left: "20%",
    display: "flex",
    transform: "translate(-20%, -20%)",
    width: 800, 
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    overflow: 'auto',
    p: 4
  },
};

const pdfModalConfig = {
  defaultStyle: modalStyles.regular,
  sales_report: {
    component: MakeReportFormModal,
    tables: ["sales","artworks","artpartners"],
    apiEndpoint: "get_sales_full_info",
    image: "artworks",
    label: "Generate Sales Report",
  },
  inventory_report: {
    component: MakeReportFormModal,
    table: "inventory",
    apiEndpoint: "get_inventory_report",
    label: "Generate Inventory Report"
  },
  global_table: {
    component: MakeGlobalPdfFormModal,
    query: true,
    label: "Make a work PDF",
  },
  global_details: {
    component: MakeGlobalPdfFormModal,
    query: false,
    label: "Make a work PDF for this records",
  },
  specific_artworks_table: {
    component: MakeSpecificArtworksPdfFormModal,
    query: true,
    label: "Make an artworks PDF",
  },
  contacts_labels: {
    component: MakeContactsLabelsModal,
    query: true,
    label: "Make a contacts labels PDF",
  },
  artworks_labels: {
    component: MakeArtworksLabelsModal,
    query: true,
    label: "Make Logistics Labels for these records",
  },
  proforma: {
    component: MakeProformaModal,
    query: true,
    label: "Make a proforma invoice",
  },
  finalInvoice: {
    component: MakeProformaModal,
    query: true,
    label: "Make a final invoice",
  },
};


export default pdfModalConfig;
