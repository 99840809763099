import React, { useState, useEffect, useMemo, useRef } from "react";
import { PDFDownloadLink, BlobProvider } from "@react-pdf/renderer";
import ArtworksLabelsPDF from "../DocumentRender/ArtworksLabelsPDF";
import {
  FormControl,
  Checkbox,
  TextField,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  Button,
  InputLabel,
  Slider,
} from "@mui/material";
import axios from "axios";
import useModelLoader from "../../../hooks/useModelLoader";
import { useSelectOptions } from "../../../hooks/useSelectOptions";
import { get } from "sortablejs";
import { getDisplayValue } from "../../../utils/utils";

function MakeArtworksLabelsModal({
  itemData,
  query,
  section,
  selectedRowIds
}) {
  const [pdfBlobUrl, setPdfBlobUrl] = useState(null);
  const [data, setData] = useState(null);
  const [finalData, setFinalData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [customValues, setCustomValues] = useState({
    config : "1_38_90",
    imageSize: "900-600",
    showImage: true
  });




  const { modelData, isLoading: isModelDataLoading } = useModelLoader(section);
  const selectMappings = useSelectOptions(modelData?.fields);


  const handleOptionChange = (event) => {
    console.log("option", event.target.name, event.target.value)
    setCustomValues({
      ...customValues,
      [event.target.name]: event.target.value,
    });
  };


  const handleCheckChange = (key, value) => {
    setCustomValues((prev) => ({ ...prev, [key]: value }));
  };


  // Using useRef to persist the axios instance
  const api = useRef(
    axios.create({
      baseURL: `${process.env.REACT_APP_APIGETAWAY_URL}`,
      headers: {
        "Content-Type": "application/json",
      },
    })
  ).current;

  api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("CognitoJWTToken");
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );





  useEffect(() => {
    if (selectedRowIds != null && selectedRowIds.length > 0) {
      const fetchItems = async () => {
        try {
          setIsLoading(true);
          const idsString = selectedRowIds.join(",");
  
          // Start with the basic query parameters
          let queryParams = {
            table: section,
            ids: idsString,
            fields: ' * '
          };
  
  
          // Construct the query string
          const queryString = new URLSearchParams(queryParams).toString();
          const response = await api.get(`?${queryString}`);
          setData(response.data);
          console.log("data", response.data);
        } catch (err) {
          console.error("Error:", err);
          setError(err);
        } finally {
          setIsLoading(false);
        }
      };
  
      fetchItems();
    }
  }, [section, selectedRowIds]); // Add fromTable and fromId to dependencies
  
  

  useEffect(() => {
if (modelData?.fields && data) {
      const processedData = data.map((item) => {
        const newItem = { ...item };

  
        modelData.fields.forEach(field => {
          newItem[field.key] = getDisplayValue(field, item[field.key], selectMappings);
        });

        return newItem;
      });

      setFinalData(processedData);
    }
  }, [data, modelData?.fields]);




  const documentMemo = useMemo(() => {
    if (!finalData) {
      return null;
    }

    let ComponentToCall;

    ComponentToCall = ArtworksLabelsPDF;
    return (
      <ComponentToCall
        customValues={customValues}
        section={section}
        data={finalData}
      />
    );
  }, [
    section,
    finalData,
    customValues
  ]);


  const selectedRowCount = selectedRowIds?.length || 0;

  useEffect(() => {
    // Reset the blob URL to force regeneration
    setPdfBlobUrl(null);
  }, [documentMemo, customValues]);

  if (isLoading || isModelDataLoading) {
    return <div>Loading...</div>; // Handle loading state for both API data and model data
  }

  return (
    <div style={{ display: "flex", width: "100%" }}>
      {/* Form Section */}
      <div
        style={{
          width: "50%",
          paddingRight: "30px",
          height: "100%",
          overflow: "auto",
        }}
      >
           {selectedRowCount} records in {section} selected
        <form>
        <Button style={{ marginRight: '10px', marginTop: '10px', float: 'right'}} variant="outlined" size="small" href={pdfBlobUrl} download={`Ceysson & Bénétière - Artworks Labels.pdf`}>
              Download PDF
        </Button>
          <FormControl component="fieldset">
            <div>
              <FormLabel component="legend">Label Size</FormLabel>
              <RadioGroup
                aria-label="config"
                name="config"
                value={customValues["config"]}
                onChange={handleOptionChange}
                row
              >
                <FormControlLabel value="1_38_90" control={<Radio />} label="38 x 90 cm" />
                <FormControlLabel value="1_152_102"  control={<Radio />} label="102 x 152 cm" />
                <FormControlLabel value="1_287_200" control={<Radio />} label="A4" />
                <FormControlLabel value="12_287_200" control={<Radio />} label="12 labels on A4 page" />

              </RadioGroup>

{/*
              <FormLabel component="legend">Options</FormLabel>
              {customValues.config === "1_38_90" && (<FormControlLabel
                key="hideImage"
                control={
                  <Checkbox
                    checked={customValues["hideImage"] || false}
                    onChange={handleOptionChange}
                    name="hideImage"
                  />
                }
                label="hide Image"
              />)}

*/ }


              <FormLabel component="legend">Image</FormLabel>


      
        <FormControlLabel
                key="showImage"
                control={
                  <Checkbox
                    checked={customValues.showImage || false}
                    onChange={(e) => handleCheckChange('showImage', e.target.checked)}
                    name="showImage"
                  />

                }
                
                label="Show Image"
              />


{customValues.showImage && <><FormLabel component="legend">Image Quality</FormLabel>
              <RadioGroup
               name="imageQuality"
                value={customValues['imageQuality']}
                onChange={handleOptionChange}
                row
              >
              <FormControlLabel
                  value="2000-1200"
                  control={<Radio />}
                  label="Very High Quality"
                />
                <FormControlLabel
                  value="900-600"
                  control={<Radio />}
                  label="High Quality"
                />
                <FormControlLabel
                  value="450-300"
                  control={<Radio />}
                  label="Medium Quality"
                />
                <FormControlLabel
                  value="132-80"
                  control={<Radio />}
                  label="Low Quality"
                />
              </RadioGroup></>}

            </div>
          </FormControl>
          <br />

          {/* PDF Download Link 
          <PDFDownloadLink
            document={documentMemo}
            fileName="Ceysson & Bénétière - Artworks Label.pdf"
            style={{
              textDecoration: "underline",
              color: "blue",
              cursor: "pointer",
            }}
          >
            {({ blob, url, loading, error }) =>
              loading ? "Loading document..." : "Download now!"
            }
          </PDFDownloadLink>*/}


          <Button style={{ marginRight: '10px', marginTop: '10px', float: 'right'}} variant="outlined" size="small" href={pdfBlobUrl} download={`Ceysson & Bénétière - Artworks Labels.pdf`}>
              Download PDF
        </Button>

          {/* BlobProvider for live preview */}
          <BlobProvider
            customValues={customValues}
            document={documentMemo}
            key={customValues}
          >
            {({ blob, url, loading, error }) => {
              if (error) {
                console.error("Error in BlobProvider: ", error);
              }
              if (!loading && blob && !error && url !== pdfBlobUrl) {
                setPdfBlobUrl(url);
              }
              return null;
            }}
          </BlobProvider>
        </form>
      </div>

      {/* PDF Preview Section */}
      <div style={{ width: "50%", paddingLeft: "20px" }}>
        {pdfBlobUrl && (
          <iframe
            src={pdfBlobUrl}
            style={{ width: "100%", height: "100%", border: "none" }}
            title="PDF Preview"
          />
        )}
      </div>
    </div>
  );
}

export default MakeArtworksLabelsModal;
