// UserMainContent.js

import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';


//stule
import "./App.css";

//my componets
import TableView from "./components/ListView/TableView.js";
import Sidebar from "./components/Sidebar/Sidebar";
import AppBar from "./components/AppBar/AppBar";
import Home from "./components/Home/Home";
import Footer from "./components/Footer/Footer";
import DetailView from "./components/DetailView/DetailView";
import ScanApp from "./components/ScanApp/ScanApp";
import LogisticsLabelsHome from "./components/LogisticsLabels/LogisticsLabelsHome.js";
import LabelPDF from "./components/LogisticsLabels/LabelPDF.js";

import Loading from "./components/Loading/Loading";


import Box from "@mui/material/Box";


import { usePermissions } from './contexts/PermissionsContext'; // Import usePermissions


import MakeDynamicJson from "./components/Management/MakeDynamicJson.js";
import MakeDynamicJsonSingleRecord from "./components/Management/MakeDynamicJsonSingleRecord.js";
import GameModals from "./components/GameModal/GameModalsLauncher.js";
import LocalDashboards from "./components/Home/LocalDashboards.js";
import MakeMultiplePublicJson from "./components/Management/MakeMultiplePublicJson.js";
import { LicenseInfo } from '@mui/x-license';

LicenseInfo.setLicenseKey('370f195299e3ef7c093e564f2ab9dd2dTz0xMDcxOTMsRT0xNzcwMjQ5NTk5MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=');

document.title = "cbapp.net"


function UserMainContent({permissions}) {
    const storedUserForQuery = localStorage.getItem('StoredUserDataFromDb');
    const parsedUserForQuery = JSON.parse(storedUserForQuery || '{}');
    const emailToUse = parsedUserForQuery ? parsedUserForQuery.email : null;


    const { setPermissions } = usePermissions(); // usePermissions hook to access setPermissions

  
    useEffect(() => {
      if (permissions) {
        console.log('useffect permissions',permissions )
        setPermissions(permissions);
      }
    }, [permissions]);

console.log("user_data_for_color", storedUserForQuery)


  return (
    <>
        <AppBar />
        <Box sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          bgcolor: 'background.paper',
          borderRadius: 1,
        }} >
        <Box className="background-color"
        >
          <Sidebar />
        </Box>
        <Box
          sx={{ flexGrow: 1, paddingTop: "10px", paddingLeft: "20px", paddingRight: "20px"  }}
        >
          <Routes>
          <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/:section/table" element={<TableView />} />
            <Route path="/:section/dashboard" element={<LocalDashboards />} />
            <Route path="/:section/table/select/:customSelect" element={<TableView />} />
            <Route path="/:section/table/default/:defaultQuery" element={<TableView />} />
            <Route path="/:section/detail/:id" element={<DetailView />} />
            <Route path="/loading" element={<Loading />} />
            <Route path="/management/makedynamicjson" element={<MakeDynamicJson />} />
            <Route path="/management/makedynamicjsonsinglerecord" element={<MakeDynamicJsonSingleRecord />} />
            <Route path="/management/makemultiplejson" element={<MakeMultiplePublicJson />} />
            <Route path="/GameModals/" element={<GameModals />} />
            <Route path="/ScanApp/" element={<ScanApp />} />
            <Route path="/logistics_labels/:componentToCall" element={<LogisticsLabelsHome />} />
            <Route path="/pdf/:label/:size/:type" element={<LabelPDF />} />
          </Routes>
        </Box>
      </Box>

      <Footer />
    </>
  );
}

  
  export default UserMainContent;
/*

  <Box>      permissions : {permissions}
          user id : {localStorage.getItem('userId')}
          StoredUserDataFromDb : {localStorage.getItem('StoredUserDataFromDb')}</Box>
        </Box>*/