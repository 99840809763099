import React, { useState, useCallback, useRef, useMemo } from 'react';

export const useImagePreview = () => {
  const [showLargerImg, setShowLargerImg] = useState(false);
  const [previewInfo, setPreviewInfo] = useState({ section: '', rowId: '', value: '', fileObject: '', bucketPhotos: ''});
  const [previewTop, setPreviewTop] = useState(0);
  const [previewLeft, setPreviewLeft] = useState(0);

  const handleMouseOver = useCallback((e, section, rowId, value, fileObject = "", bucketPhotos = "") => {
    setShowLargerImg(true);
    setPreviewInfo({ section, rowId, value, fileObject, bucketPhotos });
    setPreviewTop(e.pageY - 450);
    setPreviewLeft(e.pageX + 30);
  }, []);

  const handleMouseOut = useCallback(() => {
    setShowLargerImg(false);
  }, []);

  const lastMousePosition = useRef({ top: 0, left: 0 });

  const handleMouseMove = useCallback((e) => {
    const distanceFromTop = e.pageY - window.scrollY;
    let newTop = e.pageY - 450;

    if (distanceFromTop < 200) {
      newTop = e.pageY + 30;
    } else if (distanceFromTop < 450) {
      newTop = e.pageY - 200;
    }

    const newLeft = e.pageX + 30;

    // Évite de mettre à jour si la position n'a pas changé
    if (lastMousePosition.current.top !== newTop || lastMousePosition.current.left !== newLeft) {
      lastMousePosition.current = { top: newTop, left: newLeft };
      setPreviewTop(newTop);
      setPreviewLeft(newLeft);
    }
  }, []);

  return {
    handleMouseOver,
    handleMouseOut,
    handleMouseMove,
    showLargerImg,
    previewTop,
    previewLeft,
    previewInfo,
  };
};

const ImagePreview = ({ showLargerImg, previewTop, previewLeft, previewInfo }) => {
  if (!showLargerImg) {
    return null;
  }

  const bucketPhotos = previewInfo.bucketPhotos || "cb-business-manager";
  const imgUrl = useMemo(() => {
    let url = `${previewInfo.section}/${previewInfo.rowId}/main_image/${previewInfo.value}-900-600.jpg`;
    if (previewInfo.fileObject !== "") {
      url = previewInfo.fileObject.replace("-450-300.jpg", "-900-600.jpg");
    }
    return `https://${bucketPhotos}${process.env.REACT_APP_S3_REGION_URL}${url}`;
  }, [previewInfo, bucketPhotos]);

  return (
    <div
      style={{
        position: 'absolute',
        top: previewTop,
        left: previewLeft,
        zIndex: 9999,
      }}
    >
      <img
        src={imgUrl}
        alt="Larger preview"
        style={{ backgroundColor: 'white', padding: 15, width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '100%' }}
      />
    </div>
  );
};

export const MemoizedImagePreview = React.memo(ImagePreview);
