import React, { useState, useEffect } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Help({ helpData, helpTableDefault }) {
  const [globalHelpData, setGlobalHelpData] = useState([]);
  const [specificHelpData, setSpecificHelpData] = useState([]);


  console.log("helpTableDefault", helpTableDefault)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const specificResponse = await fetch(
          `${process.env.REACT_APP_GLOBAL_MAPPING_URL}help_texts/help_texts_${helpData}_related.json?123`
        );
        if (!specificResponse.ok) {
          throw new Error("Network response was not ok");
        }
        setSpecificHelpData(await specificResponse.json());

        if (helpTableDefault==="common") {
          const globalResponse = await fetch(
            `${process.env.REACT_APP_GLOBAL_MAPPING_URL}help_texts/help_texts_common_table_asso.json?123`
          );
          if (!globalResponse.ok) {
            throw new Error("Network response for global help was not ok");
          }
          setGlobalHelpData(await globalResponse.json());
        } else {
          setGlobalHelpData([]); // Réinitialise l'aide globale si helpTableDefault est false
        }
      } catch (error) {
        console.error("Failed to fetch help data", error);
        setGlobalHelpData([]);
        setSpecificHelpData([]);
      }
    };
    fetchData();
  }, [helpData, helpTableDefault]);

  return (
    <div style={{
      width: '100%',
      minWidth: '200px',
      position: 'relative'
    }}>
      <div style={{
        width: '100%',
        overflow: 'hidden'
      }}>
        {globalHelpData.length > 0 && (
          <div>
            <h3>Common Help</h3>
            {globalHelpData.map((item, index) => (
              <Accordion key={`global-${index}`}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} id={`global-panel${index}-header`}>
                  <Typography style={{ width: '60vw', wordWrap: 'break-word' }}>{item.question}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography style={{ width: '60vw', wordWrap: 'break-word' }}>{item.answer}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        )}

        {specificHelpData.length > 0 && (
          <div>
            <h3>Section-Specific Help</h3>
            {specificHelpData.map((item, index) => (
              <Accordion key={`specific-${index}`}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} id={`specific-panel${index}-header`}>
                  <Typography style={{ width: '60vw', wordWrap: 'break-word' }}>{item.question}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography style={{ width: '60vw', wordWrap: 'break-word' }}>{item.answer}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        )}

        {(globalHelpData.length > 0 || specificHelpData.length > 0) && (
          <Accordion key="hello_it">
            <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel_itcrowd">
              <Typography style={{ width: '60vw', wordWrap: 'break-word' }}>But it still does not work!</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography style={{ width: '60vw', wordWrap: 'break-word' }}>
                <img src="https://cb-apps-global-data-mapping.s3.eu-west-3.amazonaws.com/help_texts/hello-it.gif" alt="hello-it" /><br />
                If you want to report a bug, please be very specific and include the URL of this page with your request, and обязательно a screenshot with, if necessary, an indication of where the bug is located. On Mac, with QuickTime Player, it is very easy to record your screen for a few seconds. This will be of great help to me in resolving this bug.
              </Typography>
            </AccordionDetails>
          </Accordion>
        )}
      </div>
    </div>
  );
}

export default Help;