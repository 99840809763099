import React, { useState, useEffect, useMemo, useRef } from "react";
import { PDFDownloadLink, BlobProvider } from "@react-pdf/renderer";
import ContactsLabelsPDF from "../DocumentRender/ContactsLabelsPDF";
import {
  FormControl,
  Checkbox,
  TextField,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  Button,
  InputLabel,
  Slider,
  Divider,
  Typography,
  Grid,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";
import useModelLoader from "../../../hooks/useModelLoader";
import { useSelectOptions } from "../../../hooks/useSelectOptions";
import { getDisplayValue } from "../../../utils/utils";

function MakeContactsLabelsModal({
  itemData,
  query,
  section,
  selectedRowIds
}) {
  const [pdfBlobUrl, setPdfBlobUrl] = useState(null);
  const [data, setData] = useState(null);
  const [finalData, setFinalData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [customValues, setCustomValues] = useState({
    config: "1_38_90",
    imageSize: "900-600",
    showImage: true
  });

  const labelFormats = {
    singleLabels: [
      { value: "1_38_90", label: "Brother/Dymo (38 x 90 mm)", description: "Single label for Brother/Dymo printers" },
    ],
    averyA4: [
      { value: "12_297_210", label: "Avery 3661 (12 per page)", description: "12 labels per A4 page, 70 x 70 mm" },
      { value: "14_297_210", label: "Avery 7160/L7160 (14 per page)", description: "14 labels per A4 page, 99.1 x 38.1 mm" },
      { value: "16_297_210", label: "Avery 7162/L7162 (16 per page)", description: "16 labels per A4 page, 99.1 x 33.9 mm" },
      { value: "21_297_210", label: "Avery 7160/L7160 (21 per page)", description: "21 labels per A4 page, 63.5 x 38.1 mm" },
      { value: "24_297_210", label: "Avery 7169/L7159 (24 per page)", description: "24 labels per A4 page, 64 x 33.9 mm" },
      { value: "30_297_210", label: "Avery 3489/L6008 (30 per page)", description: "30 labels per A4 page, 70 x 29 mm" },
    ],
    averyLetter: [
      { value: "10_279_216", label: "Avery 8161 (10 per page)", description: "10 labels per US Letter page, 101.6 x 50.8 mm" },
      { value: "20_279_216", label: "Avery 8160 (20 per page)", description: "20 labels per US Letter page, 101.6 x 25.4 mm" },
      { value: "30_279_216", label: "Avery 8660 (30 per page)", description: "30 labels per US Letter page, 66.7 x 25.4 mm" },
    ],
    others: [
      { value: "8_297_210", label: "Kores L10574 / Herma 8645", description: "8 labels per A4 page, 105 x 74 mm" },
    ],
    custom: [
      { value: "custom", label: "Custom format", description: "Define a custom format" }
    ]
  };
  
  // Page formats dimensions in mm
  const pageFormats = {
    a4: { width: 210, height: 297, label: "A4 (210 x 297 mm)" },
    letter: { width: 216, height: 279, label: "US Letter (216 x 279 mm)" }
  };

  const { modelData, isLoading: isModelDataLoading } = useModelLoader(section);
  const selectMappings = useSelectOptions(modelData?.fields);

  const [customLabelSize, setCustomLabelSize] = useState({
    count: 1,
    pageFormat: "a4", // Default to A4
    pageWidth: 210,
    pageHeight: 297,
    labelWidth: 90,
    labelHeight: 38,
    marginTop: 5,
    marginLeft: 5,
    gapX: 2,
    gapY: 2,
    columns: 1,
    padding: 2
  });

  const handleOptionChange = (event) => {
    const { name, value } = event.target;
    
    // Si c'est un format personnalisé, activer la section de personnalisation
    if (name === "config" && value === "custom") {
      // Utiliser les dimensions stockées dans customLabelSize
      const customConfig = `${customLabelSize.count}_${customLabelSize.pageHeight}_${customLabelSize.pageWidth}`;
      setCustomValues({
        ...customValues,
        [name]: customConfig,
        isCustom: true
      });
    } else {
      setCustomValues({
        ...customValues,
        [name]: value,
        isCustom: false
      });
    }
  };

  const handleCheckChange = (key, value) => {
    setCustomValues((prev) => ({ ...prev, [key]: value }));
  };

  const handlePageFormatChange = (event) => {
    const formatKey = event.target.value;
    const format = pageFormats[formatKey];
    
    setCustomLabelSize(prev => {
      const newValues = { 
        ...prev, 
        pageFormat: formatKey,
        pageWidth: format.width, 
        pageHeight: format.height 
      };
      
      // Recalculer le nombre d'étiquettes avec les nouvelles dimensions de page
      const availWidth = newValues.pageWidth - (2 * newValues.marginLeft);
      const availHeight = newValues.pageHeight - (2 * newValues.marginTop);
      
      // Calculer combien de colonnes peuvent tenir horizontalement
      const columnsNumber = Math.floor(availWidth / (newValues.labelWidth + newValues.gapX));
      
      // Calculer combien de rangées peuvent tenir verticalement
      const rowsNumber = Math.floor(availHeight / (newValues.labelHeight + newValues.gapY));
      
      newValues.columns = columnsNumber > 0 ? columnsNumber : 1;
      newValues.count = columnsNumber > 0 && rowsNumber > 0 ? columnsNumber * rowsNumber : 1;
      
      // Mettre à jour la configuration personnalisée dans customValues
      const customConfig = `${newValues.count}_${newValues.pageHeight}_${newValues.pageWidth}`;
      setCustomValues(cv => ({
        ...cv,
        config: customConfig
      }));
      
      return newValues;
    });
  };

  const handleCustomSizeChange = (event) => {
    const { name, value } = event.target;
    const numValue = parseFloat(value);
  
    setCustomLabelSize(prev => {
      const newValues = { ...prev, [name]: numValue };
  
      // Recalculer le nombre d'étiquettes si les dimensions changent
      if (['labelWidth', 'labelHeight', 'marginTop', 'marginLeft', 'gapX', 'gapY'].includes(name)) {
        const availWidth = newValues.pageWidth - (2 * newValues.marginLeft);
        const availHeight = newValues.pageHeight - (2 * newValues.marginTop);
  
        // Calculer combien de colonnes peuvent tenir horizontalement
        const columnsNumber = Math.floor(availWidth / (newValues.labelWidth + newValues.gapX));
  
        // Calculer combien de rangées peuvent tenir verticalement
        const rowsNumber = Math.floor(availHeight / (newValues.labelHeight + newValues.gapY));
  
        newValues.columns = columnsNumber > 0 ? columnsNumber : 1;
        newValues.count = columnsNumber > 0 && rowsNumber > 0 ? columnsNumber * rowsNumber : 1;
      }
  
      // Mettre à jour la configuration personnalisée dans customValues
      const customConfig = `${newValues.count}_${newValues.pageHeight}_${newValues.pageWidth}`;
      setCustomValues(cv => ({
        ...cv,
        config: customConfig
      }));
  
      return newValues;
    });
  };
  

  // Using useRef to persist the axios instance
  const api = useRef(
    axios.create({
      baseURL: `${process.env.REACT_APP_APIGETAWAY_URL}`,
      headers: {
        "Content-Type": "application/json",
      },
    })
  ).current;

  api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("CognitoJWTToken");
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    if (selectedRowIds != null && selectedRowIds.length > 0) {
      const fetchItems = async () => {
        try {
          setIsLoading(true);
          const idsString = selectedRowIds.join(",");
  
          // Start with the basic query parameters
          let queryParams = {
            table: section,
            custom_specific: 'get_addresses_for_contacts',
            ids: idsString
          };
  
          // Construct the query string
          const queryString = new URLSearchParams(queryParams).toString();
          const response = await api.get(`?${queryString}`);
          setData(response.data);
        } catch (err) {
          console.error("Error:", err);
          setError(err);
        } finally {
          setIsLoading(false);
        }
      };
  
      fetchItems();
    }
  }, [section, selectedRowIds]);

  useEffect(() => {
    if (modelData?.fields && data) {
      const processedData = data.map((item) => {
        const newItem = { ...item };

        // Process each field using getDisplayValue
        modelData.fields.forEach(field => {
          newItem[field.key] = getDisplayValue(field, item[field.key], selectMappings, false, true);
        });

        return newItem;
      });

      setFinalData(processedData);
    }
  }, [data, modelData?.fields]);

  const documentMemo = useMemo(() => {
    if (!finalData) {
      return null;
    }

    return (
      <ContactsLabelsPDF
        customValues={customValues}
        section={section}
        data={finalData}
      />
    );
  }, [
    section,
    finalData,
    customValues
  ]);

  const selectedRowCount = selectedRowIds?.length || 0;

  useEffect(() => {
    // Reset the blob URL to force regeneration
    setPdfBlobUrl(null);
  }, [documentMemo, customValues]);

  if (isLoading || isModelDataLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ display: "flex", width: "100%" }}>
      {/* Form Section */}
      <div
        style={{
          width: "50%",
          paddingRight: "30px",
          height: "calc(100vh - 180px)",
          overflow: "auto",
        }}
      >
        <Box mb={2}>
          <Typography variant="subtitle1">
            {selectedRowCount} contacts sélectionnés pour impression d'étiquettes
          </Typography>
        </Box>
        
        <form>
          <Button 
            style={{ marginRight: '10px', marginBottom: '20px', float: 'right'}} 
            variant="contained" 
            color="primary"
            href={pdfBlobUrl} 
            download={`Ceysson & Bénétière - Contacts Labels.pdf`}
            disabled={!pdfBlobUrl}
          >
            Télécharger PDF
          </Button>


              <Typography variant="h6">Format d'étiquette</Typography>
  
 
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormLabel component="legend">Étiquettes individuelles</FormLabel>
                  <RadioGroup
                    name="config"
                    value={customValues.config}
                    onChange={handleOptionChange}
                  >
                    {labelFormats.singleLabels.map(format => (
                      <FormControlLabel 
                        key={format.value} 
                        value={format.value} 
                        control={<Radio />} 
                        label={
                          <Typography>
                            {format.label}
                            <Typography variant="caption" display="block" color="textSecondary">
                              {format.description}
                            </Typography>
                          </Typography>
                        }
                      />
                    ))}
                  </RadioGroup>
                </Grid>

                <Grid item xs={12}>
                  <Divider style={{ margin: '10px 0' }} />
                  <FormLabel component="legend">Format Avery (A4)</FormLabel>
                  <RadioGroup
                    name="config"
                    value={customValues.config}
                    onChange={handleOptionChange}
                  >
                    {labelFormats.averyA4.map(format => (
                      <FormControlLabel 
                        key={format.value} 
                        value={format.value} 
                        control={<Radio />} 
                        label={
                          <Typography>
                            {format.label}
                            <Typography variant="caption" display="block" color="textSecondary">
                              {format.description}
                            </Typography>
                          </Typography>
                        }
                      />
                    ))}
                  </RadioGroup>
                </Grid>

                <Grid item xs={12}>
                  <Divider style={{ margin: '10px 0' }} />
                  <FormLabel component="legend">Format Avery (US Letter)</FormLabel>
                  <RadioGroup
                    name="config"
                    value={customValues.config}
                    onChange={handleOptionChange}
                  >
                    {labelFormats.averyLetter.map(format => (
                      <FormControlLabel 
                        key={format.value} 
                        value={format.value} 
                        control={<Radio />} 
                        label={
                          <Typography>
                            {format.label}
                            <Typography variant="caption" display="block" color="textSecondary">
                              {format.description}
                            </Typography>
                          </Typography>
                        } />
                      ))}
                  </RadioGroup>
                </Grid>


                <Grid item xs={12}>
                  <Divider style={{ margin: '10px 0' }} />
                  <FormLabel component="legend">Others</FormLabel>
                  <RadioGroup
                    name="config"
                    value={customValues.config}
                    onChange={handleOptionChange}
                  >
                    {labelFormats.others.map(format => (
                      <FormControlLabel 
                        key={format.value} 
                        value={format.value} 
                        control={<Radio />} 
                        label={
                          <Typography>
                            {format.label}
                            <Typography variant="caption" display="block" color="textSecondary">
                              {format.description}
                            </Typography>
                          </Typography>
                        } />
                      ))}
                  </RadioGroup>
                </Grid>

                <Grid item xs={12}>
                  <Divider style={{ margin: '10px 0' }} />




         
                
                </Grid>
              </Grid>
   

          <Button 
            style={{ marginTop: '20px', float: 'right'}} 
            variant="contained" 
            color="primary"
            href={pdfBlobUrl} 
            download={`Ceysson & Bénétière - Contacts Labels.pdf`}
            disabled={!pdfBlobUrl}
          >
            Télécharger PDF
          </Button>

          {/* BlobProvider for live preview */}
          <BlobProvider
            document={documentMemo}
            key={JSON.stringify(customValues)}
          >
            {({ blob, url, loading, error }) => {
              if (error) {
                console.error("Error in BlobProvider: ", error);
              }
              if (!loading && blob && !error && url !== pdfBlobUrl) {
                setPdfBlobUrl(url);
              }
              return null;
            }}
          </BlobProvider>
        </form>
      </div>

      {/* PDF Preview Section */}
      <div style={{ 
        width: "50%", 
        paddingLeft: "20px", 
        height: "calc(100vh - 180px)", 
        border: "1px solid #ccc",
        borderRadius: "4px",
        position: "relative"
      }}>
        {pdfBlobUrl ? (
          <iframe
            src={pdfBlobUrl}
            style={{ width: "100%", height: "100%", border: "none" }}
            title="PDF Preview"
          />
        ) : (
          <div style={{ 
            display: "flex", 
            justifyContent: "center", 
            alignItems: "center", 
            height: "100%",
            color: "#999" 
          }}>
            <Typography variant="body1">Aperçu PDF en cours de génération...</Typography>
          </div>
        )}
      </div>
    </div>
  );
}

export default MakeContactsLabelsModal;