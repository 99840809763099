import React, { useState } from 'react';
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';


import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
  } from "@tanstack/react-query"; 

  import { updateItem, deleteItem, postItems } from "../../api";

const ActionButtons = ({ section, fromTable, actionButtonsConfig, fromId, thisId, handleRefresh, handleThisRefresh }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);




        const mutationSetMain = useMutation({
            mutationFn: (args) => {
                const field = args === "invoicing_addresses_id" ? "invoicing_addresses_id" : "send_addresses_id" ? "send_addresses_id" : section + '_id';
                return updateItem(fromTable, fromId, field, thisId);
                console.log(field)
            },
            onSuccess: () => {
                handleRefresh();
            },
            onError: (error) => {
                console.error("Error posting data:", error);
            },
        });
        


      const addHistory = useMutation({
        mutationFn: (data) =>
          postItems(`history_${fromTable}`, data, true, true),
        onSuccess: () => {
          console.log("History successfully recorded");
        },
        onError: (error) => {
          console.error("Error recording history:", error);
        },
      });


      const mutationDetach = useMutation({
        mutationFn: (args) => {
            let junctionTable = fromTable + "_" + section; 
            if(args){
                junctionTable = section + "_" + fromTable; 
            }
            const fields = {};
            fields[fromTable + '_id'] = Number(fromId);
            fields[section+'_id'] = Number(thisId);
            return deleteItem(junctionTable, fields);
        },
        onSuccess: (data) => {
            handleThisRefresh();

            addHistory.mutate({
                field_name: fromTable, // The name of the field being changed or added
                [`${fromTable}_id`]: fromId, // Use computed property names for dynamic keys
                old_value: `detached 1 ${section}`, // Meaningful description of the old value
                new_value: thisId,
                event_type:2,
              });
        },
        onError: (error) => {
            console.error("Error posting data:", error);
        },
    });



    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    

    const handleClickOnLinkMenu = (action) => {
        if(action=="set_main"){
            mutationSetMain.mutate();
        }
        else if(action=="set_invoice_address"){
            mutationSetMain.mutate("invoicing_addresses_id");
        }
        else if(action=="set_send_address"){
            mutationSetMain.mutate("send_addresses_id");
        }
        else if(action=="detach"){
            mutationDetach.mutate();
        }
        else if(action=="detach_reverse"){
            mutationDetach.mutate(true);
        }
       // mutation.mutate();
        handleClose();
    };





    const handleClose = () => {
        setAnchorEl(null);
    };

    // Safely access action buttons for the section
    const buttons = actionButtonsConfig && actionButtonsConfig[fromTable] ? actionButtonsConfig[fromTable] : [];

    return (
        <div>
            <IconButton
                aria-label="more"
                aria-controls="action-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="action-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
            >
                {buttons.map((action) => (
                    <MenuItem key={action.action} onClick={() => handleClickOnLinkMenu(action.action)}>
                    {action.text}
                </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default ActionButtons;
