import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Hub } from '@aws-amplify/core';
import { fetchAuthSession, getCurrentUser, signOut, fetchUserAttributes } from '@aws-amplify/auth';
import { Amplify } from '@aws-amplify/core';
import axios from 'axios';
import awsmobile from '../aws-exports';

// Configure Amplify
Amplify.configure(awsmobile);

function useAuth() {
  const [user, setUser] = useState(null);
  const [authLoading, setAuthLoading] = useState(true);
  const [authOkay, setAuthOkay] = useState(false);
  const [token, setToken] = useState(localStorage.getItem('CognitoJWTToken') || null);
  const [userId, setUserId] = useState(localStorage.getItem('userId') || null);
  const [permissions, setPermissions] = useState(null);
  const [permissionsFetched, setPermissionsFetched] = useState(false); // Add this state
  const [userEmail, setUserEmail] = useState(null);

  const navigate = useNavigate();

  // Create an Axios instance with the base URL and headers
  const api = axios.create({
    baseURL: process.env.REACT_APP_APIGETAWAY_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  // Function to store JWT token in localStorage
  const storeToken = (jwtToken) => {
    const decodedToken = JSON.parse(atob(jwtToken.split('.')[1]));
    localStorage.setItem('CognitoJWTToken', jwtToken);
    setToken(jwtToken);
    localStorage.setItem('TokenExpiry', decodedToken.exp.toString());
  };

  // Function to check if the token has expired
  const isTokenExpired = () => {
    const expiryTime = localStorage.getItem('TokenExpiry');
    if (!expiryTime) return true;
    return Math.floor(Date.now() / 1000) > parseInt(expiryTime, 10);
  };

  const handleLogout = async (message = null) => {
    try {
      // Clear all local storage first
      localStorage.removeItem('StoredUserDataFromDb41');
      localStorage.removeItem('CognitoJWTToken');
      localStorage.removeItem('TokenExpiry');
      localStorage.removeItem('userId');

      // Clear all states
      setUser(null);
      setAuthOkay(false);
      setToken(null);
      setUserId(null);
      setPermissions(null);
      setPermissionsFetched(false); // Reset this state
      setUserEmail(null);
      localStorage.setItem('authMessage', message);

      // Sign out last
      await signOut({ global: true });

      // Navigate after signout
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  // Function to check authentication status
  const checkAuth = async () => {
    try {
      const session = await fetchAuthSession();
      const currentUser = await getCurrentUser();
      const attributes = await fetchUserAttributes(); // Get user attributes

      if (session?.tokens?.idToken) {
        setUser(currentUser);
        setUserEmail(attributes.email); // Store email
        storeToken(session.tokens.idToken.toString());
        setAuthOkay(true);
      } else {
        throw new Error('No valid session');
      }
    } catch (error) {
      console.error('Error in checkAuth:', error);
      setUser(null);
      setUserEmail(null);
      setAuthOkay(false);
      setToken(null);
    } finally {
      setAuthLoading(false);
    }
  };

  useEffect(() => {
    const authListener = (data) => {
      const { event } = data.payload;

      switch (event) {
        case 'signedIn':
          checkAuth();
          break;
        case 'signedOut':
          setUser(null);
          setAuthOkay(false);
          setToken(null);
          setUserId(null);
          setPermissions(null);
          setPermissionsFetched(false); // Reset this state
          setUserEmail(null);
          break;
        case 'tokenRefresh':
          checkAuth();
          break;
        case 'signInFailure':
          setAuthOkay(false);
          break;
        default:
          break;
      }
    };

    // Store the unsubscribe function
    const unsubscribe = Hub.listen('auth', authListener);
    checkAuth();

    // Use the unsubscribe function in cleanup
    return () => {
      unsubscribe();
    };
  }, []);

  // Effect to fetch user data from API
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        localStorage.setItem('authMessage', '');
        if (user && token && !userId && userEmail) {
          const response = await api.get(
            `?table=users&loopforfields=loop&fields=id,name,users_id,is_valid,employees_id,theme,theme_mode&email=${userEmail}`
          );

          const userData = response.data[0];
          if (userData) {
            if (userData.is_valid != 1) {
              handleLogout('You have been logged out because no data was found.');
            } else {
              setUserId(userData.id);
              localStorage.setItem('userId', userData.id);
              localStorage.setItem('employeeId', userData.employees_id);
              localStorage.setItem('StoredUserDataFromDb', JSON.stringify(userData));
              localStorage.removeItem('userPermissions'); 
            }
          } else {
            handleLogout('You have been logged out, no data was found.');
          }
        }
      } catch (error) {
        handleLogout('You have been logged out because no data found.');
      }
    };

    if (authOkay && user && !userId && userEmail) {
      fetchUserData();
    }
  }, [authOkay, user, token, userId, userEmail]);

  // Effect to fetch permissions
// Dans l'effet de récupération des permissions
useEffect(() => {
  const fetchPermissions = async () => {
    try {
      // Récupérer d'abord du localStorage
      const storedPermissions = localStorage.getItem('userPermissions');
      
      // Si on a un userId et soit pas de permissions stockées, soit permissionsFetched est false
      if (userId && (!storedPermissions || !permissionsFetched)) {
        const response = await api.get(
          `?table=users&custom_specific=get_user_permission&from_id=${userId}`
        );
        const permissionsData = response.data.map(p => p.name);
        
        // Stocker les permissions dans le state et localStorage
        setPermissions(permissionsData);
        // Stocker même si c'est un tableau vide, avec JSON.stringify
        localStorage.setItem('userPermissions', JSON.stringify(permissionsData));
        setPermissionsFetched(true);
      } else if (storedPermissions && !permissions) {
        // Si on a des permissions stockées mais pas dans le state
        setPermissions(JSON.parse(storedPermissions));
        setPermissionsFetched(true);
      }
    } catch (error) {
      console.error('Error fetching permissions:', error);
    }
  };

  if (userId && (!permissionsFetched || !permissions)) {
    fetchPermissions();
  }
}, [userId, permissionsFetched, permissions]);
  // Effect to handle token expiration
  useEffect(() => {
    const interval = setInterval(() => {
      if (isTokenExpired()) {
        handleLogout();
      }
    }, 60000); // Check every minute

    return () => clearInterval(interval);
  }, []);

  return {
    user,
    authLoading,
    authOkay,
    handleLogout,
    userId,
    permissions,
    userEmail
  };
}

export default useAuth;
