import React, { useMemo, useId } from "react";
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography
} from "@mui/material";
import { Link } from 'react-router-dom';
import { useImagePreview, MemoizedImagePreview } from "../../../utils/imagePreview";

const SimpleTableDashboard = ({ data, section="contacts", title, sort_field="updated_at", by_me=null }) => {
  const sortedAndLimitedData = useMemo(() => {
    let filteredData = data;
    
    // Filter the data if by_me is provided
    if (by_me && sort_field=="updated_at") {
      filteredData = data.filter(item => 
        item.last_updated_by == by_me || item.created_by == by_me
      );
    } else if (by_me && sort_field=="created_at") {
      filteredData = data.filter(item =>
        item.created_by == by_me
      );
    }

    return filteredData
      .sort((a, b) => new Date(b[sort_field]) - new Date(a[sort_field]))
      .slice(0, 10);
  }, [data, sort_field, by_me], section);

  const { handleMouseOver, handleMouseOut, handleMouseMove, showLargerImg, previewTop, previewLeft, previewInfo } = useImagePreview();
  const uniqueIdPrefix = useId();

  return (
    <Card>
      <CardContent>
        <Typography variant="h5">{title || section}</Typography>
        <TableContainer elevation={0} component={Paper} sx={{ backgroundColor: "#fafafa", marginTop: 2 }}>
          <Table size="small">
            <TableHead>
              <TableRow key={section+title+by_me+sort_field+"head"}>
                <TableCell>Img</TableCell>
                <TableCell>Title</TableCell>
                <TableCell align="right">{sort_field}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedAndLimitedData.map((item) => (
                <TableRow key={section+item.id+title+by_me+sort_field}>
                  <TableCell>
                    {item.main_image ? (<img 
                      src={`https://cb-business-manager.s3.eu-west-3.amazonaws.com/${section}/${item.id}/main_image/${item.main_image}-50-40.jpg?`}      
                      loading="lazy"
                      onMouseOver={(e) => handleMouseOver(e, section, item.id, item.main_image)}
                      onMouseOut={handleMouseOut}
                      onMouseMove={handleMouseMove}
                      alt={`${item.name}`}
                    />) : ("")}
                  </TableCell>
                  <TableCell>
                    <Link to={`/${section}/detail/${item.id}`}>{item.name}</Link>
                  </TableCell>
                  <TableCell align="right">{item[sort_field]}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <MemoizedImagePreview
        showLargerImg={showLargerImg}
        previewTop={previewTop}
        previewLeft={previewLeft}
        previewInfo={previewInfo}
      />
    </Card>
  );
};

export default SimpleTableDashboard;
