import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Button,
  Select,
  MenuItem,
  CircularProgress,
  FormControl,
  InputLabel,
  TextField,
  Tooltip, 
  IconButton
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import { Link } from "react-router-dom";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { postItems, updateItem } from "../../api";
import useModelLoader from "../../hooks/useModelLoader";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import AutocompleteField from "../Autocomplete/AutocompleteField";
import * as Constants from "../../constants/selectConstants";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AutocompleteWithQuery from "../Autocomplete/AutocompleteWithQuery";
import { unslugify, getColorForSection } from "../../utils/utils";

dayjs.extend(utc);

function AddModal({ open, handleClose, section }) {
  const [formData, setFormData] = useState({});
  const [fieldsData, setFieldsData] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [insertId, setInsertId] = useState(null);
  const [slugData, setSlugData] = useState([]);
  const [conditionnalLoadValue, setConditionnalLoadValue] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [groupedFields, setGroupedFields] = useState({});
  const [loading, setLoading] = useState(true); // État pour gérer l'affichage du loader

  useEffect(() => {
    const isValid = fieldsData.every(field => !field.required || formData[field.key]);
    setIsFormValid(isValid);
  }, [formData, fieldsData]);

  const mutation = useMutation({
    mutationFn: (data) => postItems(section, data),
    enabled: false,
    onSuccess: (data) => {
      setShowSuccess(true);
      const logEntry = JSON.parse(data.body);
      const insertID = logEntry.insertID;
      setInsertId(insertID);
      if (modelData.makeSlug) {
        let slugValue = makeFinalSlug();
        slugValue = slugValue + "-" + insertID;
        mutationSlug.mutate({ id: insertID, value: slugValue });
      }
      setLoading(false); // Désactiver le loader après le succès
    },
    onError: (error) => {
      console.error("Error posting data:", error);
      setLoading(false); // Désactiver le loader en cas d'erreur
    },
  });

  const mutationSlug = useMutation({
    mutationFn: (args) => updateItem(section, args.id, "slug", args.value),
    enabled: false,
    onSuccess: (data) => {
      //console.log("slug success");
    },
    onError: (error) => {
      console.error("Error posting data:", error);
    },
  });

  const makeFinalSlug = () => {
    if (modelData?.makeSlug) {
      let newSlug = modelData.makeSlug
        .map((slug) => {
          let slugToSlugify = String(slugData[slug] || formData[slug]);
          slugToSlugify = slugToSlugify.toLowerCase().trim();
          slugToSlugify = slugToSlugify.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
          slugToSlugify = slugToSlugify.replace(/[^a-z0-9\s-]/g, ' ').trim();
          slugToSlugify = slugToSlugify.replace(/[\s-]+/g, '-');
          return slugToSlugify;
        })
        .join("-");
      return newSlug;
    }
  };

  const handleAdd = () => {
    setLoading(true); // Activer le loader lors de l'ajout
    let dataToPost = { ...formData };
    if (!formData.name && modelData?.defaultName) {
      const defaultNameValues = modelData.defaultName.map(field => formData[field] || '').join(' ');
      dataToPost.name = defaultNameValues.trim() || "Name to change";
    }
    mutation.mutate(dataToPost);
  };

  const { modelData, filteredFields } = useModelLoader(section);

  useEffect(() => {
    if (filteredFields) {
      setFieldsData(filteredFields);
    }
  }, [filteredFields]);

  useEffect(() => {
    if (fieldsData.length > 0) {
      const grouped = groupFieldsByCardOrganization(fieldsData);
      setGroupedFields(grouped);
    }
  }, [fieldsData]);

  const handleInputChange = (key, value) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const handleSlug = (key, value) => {
    setSlugData({
      ...formData,
      [key]: [value],
    });
  };

  const handleInputDateChange = (key, value) => {
    if (value && value != "0000-00-00") {
      const formattedDate = dayjs(value).format("YYYY-MM-DD");
      console.log("setFormData", formData)
      setFormData({
        ...formData,
        [key]: formattedDate,
      });
    }
  };

  const [selectOptions, setSelectOptions] = useState({});

  useEffect(() => {
    fieldsData.forEach((field) => {
      if (field.selectLocalValue) {
        setSelectOptions((prevOptions) => ({
          ...prevOptions,
          [field.key]: Constants[field.selectLocalValue],
        }));
      } else if (field.fieldType === "select" && field.selectFile) {
        const fetchData = async () => {
          try {
            const response = await fetch(
              `${process.env.REACT_APP_GLOBAL_MAPPING_URL}${
                field.selectFile
              }?${Math.floor(Math.random() * 10000)}`
            );
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            const data = await response.json();
            setSelectOptions((prevOptions) => ({
              ...prevOptions,
              [field.key]: data,
            }));
          } catch (error) {
            console.error(
              "Failed to fetch select options for",
              field.key,
              error
            );
          }
        };
        fetchData();
      }
      setLoading(false);
    });
  }, [fieldsData]);

  const groupFieldsByCardOrganization = (fields) => {
    const groupedFields = {};
    fields.forEach((field) => {
      const cardOrg = field.cardOrganization || "Main Info";
      if (groupedFields[cardOrg]) {
        groupedFields[cardOrg].push(field);
      } else {
        groupedFields[cardOrg] = [field];
      }
    });
    return groupedFields;
  };

  const sectionColor=getColorForSection(section);
  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="add-item-title">
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: fieldsData.length > 1 ? '80vw' : '40vw',
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        {loading && (
          <CircularProgress />
        )}
        {!loading && (
          <Box
            sx={{
              paddingX: 5,
              maxHeight: "85vh",
              overflow: "auto",
            }}
          >
            {showSuccess ? (
              <div>
                <p>Data inserted successfully!</p>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    p: 1,
                    m: 1,
                    bgcolor: "background.paper",
                    borderRadius: 1,
                  }}
                >
                  <Button
                    sx={{ m: 2, width: "30%" }}
                    variant="contained"
                    color="secondary"
                    onClick={handleClose}
                    component={Link}
                    to={`/${section}/detail/${insertId}`}
                  >
                    View Entry
                  </Button>
                  <Button
                    sx={{ m: 2, width: "30%" }}
                    onClick={() => {
                      setShowSuccess(false);
                      setFormData({});
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Add Another
                  </Button>
                  <Button
                    sx={{ m: 2, width: "30%" }}
                    onClick={() => {
                      setShowSuccess(false);
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Add Another one with same data
                  </Button>
                </Box>
              </div>
            ) : (
              <>
  <div style={{ display: 'flex', alignItems: 'center' }}>
  <h2 id="add-item-title">
      <span
    style={{
      marginRight: '10px',
      display: 'inline-block',
      width: '6px',
      height: '16px',
      backgroundColor: sectionColor,
      marginRight: '8px',
    }}
  ></span>
    {modelData?.appTexts?.addAnItem || "Add Item " + unslugify(section)}
  </h2>

</div>
                {Object.keys(groupedFields).map((groupName) => (
                  <div key={groupName}>
                    <h3>{groupName}</h3>
                    <Grid container rowSpacing={1} spacing={{ xs: 1, sm: 2, md: 3 }} alignItems="flex-start">
                      {groupedFields[groupName].map((field) => (
                        <Grid item size={{ xs: 12, md: fieldsData.length > 1 ? 6 : 12 }} key={field.key}>
                          <Box display="flex" alignItems="center">
                            <Box flexGrow={1}>
                              {(() => {   
                                switch (field.fieldType) {
                                  case "select":
                                    const options = selectOptions[field.key] || [];
                                    return (
                                      <FormControl fullWidth margin="normal">
                                        <InputLabel className="uppercase">
                                       <span style={{textTransform: "capitalize"}}>{field.title}</span> {field.required && <span>*</span>}
                                        </InputLabel>
                                        <Select
                                          label={field.title}
                                          value={field.selectLocalValue == "BOOLEAN_SELECT_OPTIONS" && Number(formData[field.key]) != 1 ? "0" : formData[field.key]}
                                          onChange={(e) => {
                                            handleInputChange(field.key, e.target.value);
                                          }}
                                          required={field.required}
                                        >
                                          {options.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                              {option.n || option.name}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    );
                                  case "autocomplete":
                                    return (
                                      <AutocompleteField
                                        title={field.title}
                                        required={field.required}
                                        selectFile={field.selectFile}
                                        selectFileKey={field.selectFileKey}
                                        selectFreesolo={field.selectFreesolo}
                                        conditionnalFileLoad={field.conditionnalFileLoad}
                                        formData={formData}
                                        onValueChange={(value) =>
                                          handleInputChange(field.key, value)
                                        }
                                      />
                                    );
                                  case "autocompleteWithQuery":
                                    return (
                                      <AutocompleteWithQuery
                                        key={field.key}
                                        title={field.title}
                                        required={field.required}
                                        selectFile={field.selectFile}
                                        selectFileKey={field.selectFileKey}
                                        selectFreesolo={field.selectFreesolo}
                                        conditionnalFileLoad={field.conditionnalFileLoad}
                                        formData={formData}
                                        sendBackJson={true}
                                        value={formData[field.key]}
                                        onValueChange={(value) => {
                                          handleInputChange(field.key, value.id);
                                          handleSlug(field.key, value.name);
                                        }}
                                      />
                                    );
                                  case "input":
                                    return (
                                      <TextField
                                        fullWidth
                                        margin="normal"
                                        label={<span style={{textTransform: "capitalize"}}>{field.title}</span>}
                                        variant="outlined"
                                        type="text"
                                        value={formData[field.key] || ""}
                                        onChange={(e) =>
                                          handleInputChange(field.key, e.target.value)
                                        }
                                        required={field.required}
                                      />
                                    );
                                  case "date":
                                    return (
                                      <div>
                                        <DatePicker
                                          format="DD/MM/YYYY"
                                          sx={{ width: "100%", mt: 2 }}
                                          label={<span style={{textTransform: "capitalize"}}>{field.title}</span>}
                                          onChange={(newDate) => {
                                            handleInputDateChange(field.key, newDate);
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              fullWidth
                                            />
                                          )}
                                        />
                                      </div>
                                    );
                                  case "datetime":
                                    return (
                                      <DateTimePicker
                                        margin="normal"
                                        label={field.title}
                                        value={formData[field.key] || dayjs()}
                                        onChange={(e) =>
                                          handleInputChange(field.key, e.target.value)
                                        }
                                        renderInput={(params) => (
                                          <TextField
                                            margin="normal"
                                            {...params}
                                            fullWidth
                                          />
                                        )}
                                      />
                                    );
                                    case "autocompleteFreeSolo":
                                                return (
                                                  <AutocompleteField
          
                                                    title={field.title}
                                                    selectFile={field.selectFile}
                                                    selectFileKey={field.selectFileKey}
                                                    selectFreesolo={true}
                                                    formData={formData}
                                                    onValueChange={(value) =>
                                                      handleInputChange(field.key, value)
                                                    }
                                                  />
                                                );
                                  default:
                                    return null;
                                }
                              })()}
                            </Box>
                            <Box width="40px" display="flex" justifyContent="center">
                              {field.helpText && (
                                <Tooltip title={field.helpText}>
                                  <IconButton
                                    size="small"
                                    style={{
                                      marginLeft: "5px",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <InfoIcon
                                      sx={{ cursor: "help" }}
                                      fontSize="small"
                                    />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </Box>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                ))}
                <Button
                  onClick={handleAdd}
                  variant="contained"
                  color="primary"
                  style={{ marginTop: "16px", width: '100%' }}
                  disabled={!isFormValid}
                >
                  Add 
                </Button>
                {isFormValid || <p style={{ color: "red" }}>Please fill in all required fields.</p>}
              </>
            )}
          </Box>
        )}
      </Box>
    </Modal>
  );
}

export default AddModal;