import React, { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Tooltip, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { loadItem } from '../../api';// Ensure this path matches where your API functions are defined

function SavePublicDataButton({section, id=null, data, single=false}) {

  // Setup the query client and mutation
  const queryClient = useQueryClient();

  let dataLoadItem = {generate_public_data: true};
  let linkSuffix = "table";
  if(single) {
linkSuffix = id;
dataLoadItem = {generate_public_data: true, single_file: true};

if(data?.noSuffix){
  linkSuffix = "main_single";
}
  } 



  const mutation = useMutation({
    mutationFn: () =>
    loadItem(section, id, dataLoadItem),
    onSuccess: () => {
    },
    onError: (err) => {
      console.error("Error updating junction table:", err);
    },
  });

  const handleSaveClick = () => {
    if (!section) {
      alert('Please set both section and ID before saving.');
      return;
    }
    mutation.mutate();
  };



const buttonHelp = data?.helpText || "This button generates static files that are used for the public part of the website. Basically, if you want something to be visible on the site, it is better to press this button in each section concerned, for example, if you modify the title of a work of art and it is on the public site. Then it is better to come here to generate the public data in the list but also in the detail of the concerned record. This is valid for all sections of the site, you can generate the public data of the list and the public data of the record. Each time it is important to generate both well if you want your data to be visible in public.";
const buttonName = data?.buttonName || "Generate Public Data";
  return (
    <div>
      <Button sx={{width: '100%'}} variant="outlined" size="small" color="primary" onClick={handleSaveClick} disabled={mutation.isLoading}>
        {buttonName}
        <Tooltip title={buttonHelp}>
          <InfoIcon sx={{cursor: 'help'}}color="primary" />
      </Tooltip>
      </Button>

      {mutation.isLoading && <p>Saving...</p>}
      {mutation.isError && <p>Error: {mutation.error.message}</p>}
      {mutation.isSuccess && (
  <div>
    <p>
      File saved successfully!
      <a  href={`${process.env.REACT_APP_CBP_PUBLIC_DATA_URL}cbp-public-json/${section}/${section}_${linkSuffix}.json`} >
        view
      </a>
    </p>
  </div>
)}
    </div>
  );
}

export default SavePublicDataButton;
