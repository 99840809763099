import React, { useState, useEffect } from "react";
import {
  DataGridPro,
  useGridApiRef,
  GridRowOrderChangeParams,
  GridToolbar
} from "@mui/x-data-grid-pro";
import { Link } from "react-router-dom";
import { Modal, Box, Button, Grid, Tooltip } from "@mui/material";
import ModalButtonsAddTo from '../Modal/ModalButtonsAddTo';
import PdfButtons from "../PDF/PdfButtons";
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import LinkIcon from '@mui/icons-material/Link';
import { useSelectOptions } from '../../hooks/useSelectOptions';
import SavePublicDataButton from "../CacheSaving/SavePublicDataButton";
import SavePrivateDataButton from "../CacheSaving/SavePrivateDataButton";
import { useImagePreview, MemoizedImagePreview } from "../../utils/imagePreview";
import ExcelButton from "../ExcelExport/ExcelButton";
import ActionButtons from "../ActionButtons/ActionButtons";


export default function GridTable({
  data,
  section,
  modeldata,
  fromTable,
  fromId,
  handleThisRefresh,
  specialTable = null,
  showGenerationButton = true,
  showLink = true
}) {
  const [checkboxSelection, setCheckboxSelection] = useState(false);
  const apiRef = useGridApiRef();
  const [rowOrder, setRowOrder] = useState([]);
  const [modalOpenSet, setModalOpenSet] = useState(false);
  const [modalOpenNarrative, setModalOpenNarrative] = useState(false);
  const [modalOpenLocation, setModalOpenLocation] = useState(false);
  const [selectedRowIds, setSelectedRowIds] = useState([]);


  const { handleMouseOver, handleMouseOut, handleMouseMove, showLargerImg, previewTop, previewLeft, previewInfo } = useImagePreview();



  // Update the row order state when the data changes
  useEffect(() => {
    setRowOrder(data.map((row) => row.id));
  }, [data]);



  let showInTableKey = "showInTable";
  if (specialTable != null) {
    showInTableKey = "showIn" + specialTable;
  }

  const linkStyle = {
    margin: "1rem",
    textDecoration: "none",
    color: '#666'
  };



  const selectMappings = useSelectOptions(modeldata?.fields);

  const bucketPhotos = modeldata?.bucketPhotos || "cb-business-manager";



  const getUniqueValuesFromData = (fieldKey) => {
    // Récupérer toutes les valeurs uniques présentes dans les données actuelles
    const uniqueValues = new Set();
    data.forEach(row => {
      if (row[fieldKey] !== null && row[fieldKey] !== undefined) {
        uniqueValues.add(row[fieldKey]);
      }
    });
    
    // Convertir en tableau d'objets pour le format attendu par DataGridPro
    return Array.from(uniqueValues).map(value => ({
      value: value,
      label: selectMappings[fieldKey] && selectMappings[fieldKey][value] 
        ? `${selectMappings[fieldKey][value]} (${value})` 
        : `${value}`
    }));
  };




  const columns = modeldata?.fields
  .filter((field) => field[showInTableKey] || field.showOnlyInMainTable)
  .map((field) => ({
    field: field.key,
    headerName: field.title,
    type: field.filterType
      ? field.filterType
      : (["select", "autocomplete", "autocompleteWithQuery"].includes(field.fieldType) ? "singleSelect" : "string"),
    valueOptions: ["select", "autocomplete", "autocompleteWithQuery"].includes(field.fieldType) 
      ? getUniqueValuesFromData(field.key)
      : [],
    flex: field.key === 'id' || field.key === 'main_image' ? 0 : 1,
    width: field.key === 'id' ? 65 : field.key === 'main_image' ? 90 : null,
    defaultHidden: field.defaultHidden === true,
    renderCell: (params) => {
      let finalValue = params.value;
      if (field.key === "fk_column") {
        field.key = section.replace("history_", "") + "_id";
        field.title = section.replace("history_", "") + "_id";
      }
      if ((field.fieldType === "select" || field.fieldType === "autocomplete" || field.fieldType === "autocompleteWithQuery") && selectMappings[field.key]) {
        if (selectMappings[field.key][finalValue] !== '' && selectMappings[field.key][finalValue] !== null && !field.noMapping) {
          finalValue = selectMappings[field.key][finalValue] + ' (' + params.value + ')' || finalValue;
        }
      }
      if (field.linkInTable && showLink) {
        finalValue = <Link style={linkStyle} to={`/${section}/detail/${params.row.id}`}>{finalValue}</Link>;
      }
      if (field.linkFkInTable) {
        const sectionToLink = field.linkFkTo || field.key.replace("_id", "");
        finalValue = (
          <div style={{ display: 'inline' }}>
            {finalValue}
            <Tooltip title={`This link will point to the ${sectionToLink} section`}>
              <Link style={linkStyle} to={`/${sectionToLink}/detail/${params.value}`}>
                <LinkIcon />
              </Link>
            </Tooltip>
          </div>
        );
      }
      if (field.renderImg && params.value) {
        const thumbnail = `https://${bucketPhotos}${process.env.REACT_APP_S3_REGION_URL}${section}/${params.row.id}/main_image/${params.value}-50-40.jpg?123`;
        return (
          <img
            src={thumbnail}
            loading="lazy"
            onMouseOver={(e) => handleMouseOver(e, section, params.row.id, params.value)}
            onMouseOut={handleMouseOut}
            onMouseMove={handleMouseMove}
            alt="Thumbnail"
          />
        );
      }
      return finalValue;
    }
  }));


    


    const handleSelectedRowsOnClick = () => {
      const selectedRowsMap = apiRef.current.getSelectedRows();
      setSelectedRowIds(Array.from(selectedRowsMap.keys()));
    };
    
    const resetSelectedRows = () => {
      setSelectedRowIds([]);
    };
  
    useEffect(() => {
      console.log(selectedRowIds); // Logging the array of IDs
    }, [selectedRowIds]);

    const handleRefresh = () => {
      console.log('handleRefresh')
    };

    if (!modeldata) return null; // or show a loading spinner


    if (modeldata && modeldata.actionButtons && Object.keys(modeldata.actionButtons).includes(fromTable)) {
      columns.push({
          field: "actions",
          headerName: "Actions",
          flex: 0.3,
          renderCell: (params) => (
              <ActionButtons section={section} fromTable={fromTable} fromId={fromId} thisId={params.row.id}  actionButtonsConfig={modeldata.actionButtons} handleRefresh={handleRefresh} handleThisRefresh={handleThisRefresh} />
          )
      });
  }


  const initialVisibilityModel = {};
  columns.forEach(column => {
    // Si defaultHidden est true, on définit la visibilité à false
    if (column.defaultHidden) {
      initialVisibilityModel[column.field] = false;
    }
  });


  return (
    <div>
<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>



  
  <Box sx={{ flexGrow: 1 }}>
  {modeldata?.pdfTableButtons || modeldata?.excelTableButtons || modeldata?.addButtons ? (
<Tooltip title="toogle checkbox to select rows">
<Button
    style={{ marginBottom: '10px' }}
    onClick={() => setCheckboxSelection(!checkboxSelection)}
  >
    <CheckBoxOutlinedIcon/> Selection Mode
  </Button>
  </Tooltip>):("")}

  {/*   <Button style={{ marginRight: '10px', marginBottom: '10px' }} onClick={handleSelectedRowsOnClick}>
  <VideogameAssetIcon/>
  </Button>*/}

  </Box>


  <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '16px', marginRight: '10px'}}>
  {modeldata?.savePublicDataButton && showGenerationButton && (
  <Box sx={{ display: 'flex', flexDirection: 'column',gap: 1, marginBottom:'10px'}}>
  <SavePublicDataButton section={section} data={modeldata?.savePublicDataButton} />
  </Box>)}

  {modeldata?.savePrivateDataButton && showGenerationButton && (
  <Box sx={{ display: 'flex', flexDirection: 'column',gap: 1, marginBottom:'10px'}}>
  <SavePrivateDataButton section={section} data={modeldata?.savePrivateDataButton} />
  </Box>)}

    {modeldata.addButtons && (
  <ModalButtonsAddTo 
          onClickHandle={handleSelectedRowsOnClick} 
          buttonNames={modeldata?.addButtons} 
          section={section} 
          selectedRowIds={selectedRowIds} 
          resetSelectedRows={resetSelectedRows}
          handleRefresh={handleRefresh}
        />)
      }


  {modeldata.pdfTableButtons && (
<PdfButtons onClickHandle={handleSelectedRowsOnClick} buttonNames={modeldata?.pdfTableButtons} section={section} selectedRowIds={selectedRowIds} resetSelectedRows={resetSelectedRows} />
)}

{modeldata.excelTableButtons && (
<ExcelButton onClickHandle={handleSelectedRowsOnClick} buttonNames={modeldata?.excelTableButtons} section={section} selectedRowIds={selectedRowIds} resetSelectedRows={resetSelectedRows} />
)}





  </Box>
</div>
<Grid item xs={12} style={{ width: '100%', maxWidth: '100vw' }}>
<DataGridPro
          sx={{ height: 'calc(100vh - 20px)', width: "100%" }}
          editMode="row"
          apiRef={apiRef}
          rows={data}
          columns={columns}
          pageSize={25}
          rowsPerPageOptions={[25, 50, 100]}
          pagination
          checkboxSelection={checkboxSelection}
          processRowUpdate={(newRow) => newRow}
          onProcessRowUpdateError={(error) => console.error(error)}
          onRowEditCommit={(params) => console.log("Edited row:", params)}
          slots={{ toolbar: GridToolbar }}
          initialState={{
            columns: {
              columnVisibilityModel: initialVisibilityModel
            }
          }}
        />


      </Grid>
      <MemoizedImagePreview
        showLargerImg={showLargerImg}
        previewTop={previewTop}
        previewLeft={previewLeft}
        previewInfo={previewInfo}
      />
     
    </div>
  );
}
