import React, { useState, useEffect } from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import fonts from "../../../utils/fonts";

const styles = StyleSheet.create({
  page: (config) => ({
    fontFamily: "Museo100",
    backgroundColor: "white",
    padding: config.padding,
  }),
  gridContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  textContainer: (config) => ({
    width: "100%",
    paddingTop: config.TCpaddingTop || 7,
    paddingRight: config.TCpaddingRight || 5,
    paddingLeft: config.TCpaddingLeft || 5,
    paddingBottom: config.TCpaddingBottom || 5,
    flexWrap: "wrap",
  }),
  section: (config) => ({
    border: "1px solid black",
    width: config.width,
    height: config.height,
    marginRight: config.indexInRow < config.columns - 1 ? config.marginRight : 0,
    marginBottom: config.indexInRow < config.entries - config.columns ? config.marginBottom : 0,
    position: "relative",
  }),
  addressLine: (config) => ({
    fontFamily: "Museo300",
    fontSize: config.fontSize || 8,
    marginBottom: 1,
  }),
  mainRecipient: (config) => ({
    fontFamily: "Museo500",
    fontSize: (config.fontSize || 8) + 1,
    marginBottom: 2,
  }),
});

const labelTemplates = {
  "1_38_90": {
    entries: 1,
    width: Math.round((90 * 72) / 25.4),
    height: Math.round((38 * 72) / 25.4),
    marginRight: 0,
    marginBottom: 0,
    columns: 1,
    rows: 1,
    pageWidth: Math.round((90 * 72) / 25.4),
    pageHeight: Math.round((38 * 72) / 25.4),
    padding: 0,
    TCpaddingTop: 10,
    TCpaddingLeft: 10,
  },
  "12_297_210": {
    entries: 12,
    width: Math.round((70 * 70) / 25.4),
    height: Math.round((70 * 72) / 25.4),
    marginRight: 0,
    marginBottom: 0,
    columns: 3,
    rows: 4,
    pageWidth: Math.round((210 * 72) / 25.4),
    pageHeight: Math.round((297 * 72) / 25.4),
    padding: 0,
  },
  "14_297_210": {
    entries: 14,
    width: Math.round((99.1 * 72) / 25.4),
    height: Math.round((38.1 * 72) / 25.4),
    marginRight: Math.round((2 * 72) / 25.4),
    marginBottom: Math.round((2 * 72) / 25.4),
    columns: 2,
    rows: 7,
    pageWidth: Math.round((210 * 72) / 25.4),
    pageHeight: Math.round((297 * 72) / 25.4),
    padding: 5,
  },
  "16_297_210": {
    entries: 16,
    width: Math.round((99.1 * 72) / 25.4),
    height: Math.round((33.9 * 72) / 25.4),
    marginRight: Math.round((2 * 72) / 25.4),
    marginBottom: Math.round((2 * 72) / 25.4),
    columns: 2,
    rows: 8,
    pageWidth: Math.round((210 * 72) / 25.4),
    pageHeight: Math.round((297 * 72) / 25.4),
    padding: 5,
  },
  "21_297_210": {
    entries: 21,
    width: Math.round((63.5 * 72) / 25.4),
    height: Math.round((38.1 * 72) / 25.4),
    marginRight: Math.round((2 * 72) / 25.4),
    marginBottom: Math.round((2 * 72) / 25.4),
    columns: 3,
    rows: 7,
    pageWidth: Math.round((210 * 72) / 25.4),
    pageHeight: Math.round((297 * 72) / 25.4),
    padding: 15,
  },
  "24_297_210": {
    entries: 24,
    width: Math.round((64 * 72) / 25.4),
    height: Math.round((33.9 * 72) / 25.4),
    marginRight: Math.round((4 * 72) / 25.4),
    marginBottom: Math.round((2 * 72) / 25.4),
    columns: 3,
    rows: 8,
    pageWidth: Math.round((210 * 72) / 25.4),
    pageHeight: Math.round((297 * 72) / 25.4),
    padding: 10,
  },
  "30_297_210": {
    entries: 30,
    width: Math.round((70 * 72) / 25.4),
    height: Math.round((29.7 * 72) / 25.4),
    marginRight: 0,
    marginBottom: 0,
    columns: 3,
    rows: 10,
    pageWidth: Math.round((210 * 72) / 25.4),
    pageHeight: Math.round((297 * 72) / 25.4),
    padding: 0,
  },
  "10_279_216": {
    entries: 10,
    width: Math.round((101.6 * 72) / 25.4),
    height: Math.round((50.8 * 72) / 25.4),
    marginRight: Math.round((2 * 72) / 25.4),
    marginBottom: Math.round((2 * 72) / 25.4),
    columns: 2,
    rows: 5,
    pageWidth: Math.round((216 * 72) / 25.4),
    pageHeight: Math.round((279 * 72) / 25.4),
    padding: 5,
  },
  "20_279_216": {
    entries: 20,
    width: Math.round((101.6 * 72) / 25.4),
    height: Math.round((25.4 * 72) / 25.4),
    marginRight: Math.round((2 * 72) / 25.4),
    marginBottom: Math.round((2 * 72) / 25.4),
    columns: 2,
    rows: 10,
    pageWidth: Math.round((216 * 72) / 25.4),
    pageHeight: Math.round((279 * 72) / 25.4),
    padding: 5,
  },
  "30_279_216": {
    entries: 30,
    width: Math.round((66.7 * 72) / 25.4),
    height: Math.round((25.4 * 72) / 25.4),
    marginRight: Math.round((2 * 72) / 25.4),
    marginBottom: Math.round((1 * 72) / 25.4),
    columns: 3,
    rows: 10,
    pageWidth: Math.round((216 * 72) / 25.4),
    pageHeight: Math.round((279 * 72) / 25.4),
    padding: 10,
  },
  "8_297_210": {
    entries: 8,
    width: Math.round((104 * 72) / 25.4),
    height: Math.round((74 * 72) / 25.4),
    marginRight: 0,
    marginBottom: 0,
    columns: 2,
    rows: 4,
    pageWidth: Math.round((210 * 72) / 25.4),
    pageHeight: Math.round((297 * 72) / 25.4),
    padding: 0,
    TCpaddingTop: 20,
    TCpaddingLeft: 20,


  },
  "custom": {
    entries: 1,
    width: 0,
    height: 0,
    marginRight: 0,
    marginBottom: 0,
    columns: 1,
    rows: 1,
    pageWidth: 0,
    pageHeight: 0,
    padding: 0,
  },
};


const defaultLabelConfig = {
    entries: 1,
    width: Math.round((90 * 72) / 25.4),
    height: Math.round((38 * 72) / 25.4),
    marginRight: 0,
    marginBottom: 0,
    columns: 1,
    rows: 1,
    pageWidth: Math.round((210 * 72) / 25.4),
    pageHeight: Math.round((297 * 72) / 25.4),
    padding: 5,
  };



const ContactsLabelsPDF = ({ customValues, data }) => {
    const [labelConfig, setLabelConfig] = useState(defaultLabelConfig);
    const [groupedData, setGroupedData] = useState([]);
  
    useEffect(() => {
      fonts();
    }, []);
  
    const organizeDataByPages = (data, config) => {
      const { entries } = config;
      const result = [];
  
      for (let i = 0; i < data.length; i += entries) {
        result.push(data.slice(i, i + entries));
      }
  
      return result;
    };
  
    useEffect(() => {
        const configKey = customValues.config;
      if (labelTemplates[configKey]) {
          setLabelConfig(labelTemplates[configKey]);
        }
      }, [customValues]);


    useEffect(() => {
      if (labelConfig && data) {
        const organizedData = organizeDataByPages(data, labelConfig);
        setGroupedData(organizedData);
      }
    }, [data, labelConfig]);
  
    return (
      <Document>
        {groupedData.map((pageData, pageIndex) => (
          <Page
            key={pageIndex}
            style={styles.page(labelConfig)}
            size={[labelConfig.pageWidth, labelConfig.pageHeight]}
          >
            <View style={styles.gridContainer}>
              {pageData.map((item, itemIndex) => {
                const rowIndex = Math.round(itemIndex / labelConfig.columns);
                const colIndex = itemIndex % labelConfig.columns;
                const configWithIndex = {
                  ...labelConfig,
                  indexInRow: colIndex,
                  indexInColumn: rowIndex,
                };
  
                return (
                  <View
                    key={itemIndex}
                    style={styles.section(configWithIndex)}
                  >
                    <View style={styles.textContainer(configWithIndex)}>
                      <Text style={styles.mainRecipient(configWithIndex)}>
                        {item.recipient}
                      </Text>
                      {item.recipient_2 && (
                        <Text style={styles.addressLine(configWithIndex)}>
                          {item.recipient_2}
                        </Text>
                      )}
                      <Text style={styles.addressLine(configWithIndex)}>
                        {item.address}
                      </Text>
                      {item.address_2 && (
                        <Text style={styles.addressLine(configWithIndex)}>
                          {item.address_2}
                        </Text>
                      )}
                      {item.state && item.show_state_in_labels && (
                        <Text style={styles.addressLine(configWithIndex)}>
                          {item.state}
                        </Text>
                      )}
                      <Text style={styles.addressLine(configWithIndex)}>
                        {item.show_zip_code_after_city
                          ? `${item.zip_code} ${item.city}`
                          : `${item.city} ${item.zip_code}`}
                      </Text>
                      <Text style={styles.addressLine(configWithIndex)}>
                        {item.country}
                      </Text>
                    </View>
                  </View>
                );
              })}
            </View>
          </Page>
        ))}
      </Document>
    );
  };
  
  export default ContactsLabelsPDF;