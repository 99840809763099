import React, { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Tooltip, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { loadItem } from '../../api';// Ensure this path matches where your API functions are defined

function SavePrivateDataButton({section, id=null, data, single=false}) {

  // Setup the query client and mutation
  const queryClient = useQueryClient();

  let dataLoadItem = {generate_private_data: true};



  let link = `dynamic_json/${section}`;
  if(single) {
    link = `single_file_json/${section}_${id}`;
dataLoadItem = {generate_private_data: true, single_file: true};
  }

  const mutation = useMutation({
    mutationFn: () =>
    loadItem(section, id, dataLoadItem),
    onSuccess: () => {
    },
    onError: (err) => {
      console.error("Error updating junction table:", err);
    },
  });

  const handleSaveClick = () => {
    if (!section) {
      alert('Please set both section and ID before saving.');
      return;
    }
    mutation.mutate();
  };



const buttonHelp = data?.helpText || "This button generates private data that will be used in several places on the site, for example in the case of a list of establishments, if you want to assign a work to an establishment, it will call on the list that has been generated here to create an establishment selector so as not to have to query the database each time, but this means that if you add a new establishment, you will have to come to the list of establishments and generate the data. This is why when this button is present in a section in which you are looking for something or for which you need something it is preferable to generate new Data.";
const buttonName = data?.buttonName || "Generate Private Data";
  return (
    <div>
      <Button sx={{width: '100%', height:"auto" }}  size="small" variant="outlined" color="primary" onClick={handleSaveClick} disabled={mutation.isLoading}>
        {buttonName}
        <Tooltip title={buttonHelp}>
          <InfoIcon sx={{cursor: 'help'}} color="primary"/>
      </Tooltip>
      </Button>

      {mutation.isLoading && <p>Saving...</p>}
      {mutation.isError && <p>Error: {mutation.error.message}</p>}
      {mutation.isSuccess && (
  <div>
    <p>
    File saved successfully!
      <a  href={`${process.env.REACT_APP_GLOBAL_MAPPING_URL}${link}.json`} >
        view
      </a>
    </p>
  </div>
)}
    </div>
  );
}

export default SavePrivateDataButton;
