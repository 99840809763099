import { Link } from "react-router-dom";
import dayjs from "dayjs";
import "dayjs/locale/fr"; // Import the French locale
import utc from 'dayjs/plugin/utc'; // Import UTC plugin if needed for UTC manipulations
import timezone from 'dayjs/plugin/timezone';
import HoverablePreviewTrigger from "../components/DetailView/HoverablePreviewTrigger";


dayjs.extend(utc); // Use the UTC plugin
dayjs.extend(timezone); // Use the timezone plugin





  /**
 * Formats a date based on the given language.
 * @param {string} date - The date string to format.
 * @param {string} language - The language code (e.g., "EN" for English, "FR" for French).
 * @param {string} [format="YYYY-MM-DD"] - Optional. The format string to use.
 * @returns {string} - The formatted date string.
 */


const createMarkup = (htmlContent) => {
    return { __html: htmlContent };
  };

export  const getDisplayValue = (field, value, selectMappings, makeLink=false, noOriginalValueInBracket=false) => {
  if(value){
    let dataToShow = value;

    if (field.selectFile && selectMappings[field.key]) {
      dataToShow = selectMappings[field.key][value];
      if (!noOriginalValueInBracket) {
        dataToShow += ` (${value})`;
      }
    }
    else if (field.fieldType === "text") {
      dataToShow = value; // Since you can't use JSX like <div> here, return the raw value
    } else if (field.selectLocalValue === "BOOLEAN_SELECT_OPTIONS") {
      dataToShow = value != 1 ? "No" : "Yes";
    } else if (field.fieldType === "switch") {
      dataToShow = value ? "On" : "Off";
    } else if (field.fieldType === "text") {
        dataToShow = (
          <div dangerouslySetInnerHTML={createMarkup(value)} />
        );
      } else if (field.fieldType === "date") {
      if (value && value !== "0000-00-00") {
        const localTime = dayjs(value)?.format("YYYY-MM-DD");
        dataToShow = localTime;
      }
    } else if (field.fieldType === "datetime") {
      if (value && value !== "0000-00-00 00:00:00") {
        const utcTime = dayjs.utc(value).format("YYYY-MM-DD HH:mm:ss");
        const newYorkTime = dayjs.tz(value, "UTC").tz("America/New_York").format("YYYY-MM-DD HH:mm:ss");
        const parisTime = dayjs.tz(value, "UTC").tz("Europe/Paris").format("YYYY-MM-DD HH:mm:ss");
        const tokyoTime = dayjs.tz(value, "UTC").tz("Asia/Tokyo").format("YYYY-MM-DD HH:mm:ss");
        dataToShow = `UTC: ${utcTime}, New York: ${newYorkTime}, Paris: ${parisTime}, Tokyo: ${tokyoTime}`;
      }
    }else if (field.fieldType === "time") {
      if (value && value !== "00:00:00") {
        const utcTime = dayjs.utc(`2023-01-01T${value}`).format("HH:mm");
        const newYorkTime = dayjs.tz(`2023-01-01T${value}`, "UTC").tz("America/New_York").format("HH:mm");
        const parisTime = dayjs.tz(`2023-01-01T${value}`, "UTC").tz("Europe/Paris").format("HH:mm");
        const tokyoTime = dayjs.tz(`2023-01-01T${value}`, "UTC").tz("Asia/Tokyo").format("HH:mm");
        dataToShow = `UTC: ${utcTime}, New York: ${newYorkTime}, Paris: ${parisTime}, Tokyo: ${tokyoTime}`;
      }
    } else if (field.customFunction) {
      const customFunctionFinal = new Function('return ' + field.customFunction)();
      dataToShow = customFunctionFinal(dataToShow);
    }
    else if (field.fieldType === "link") {
      if(value){
      dataToShow = (<Link to={value}>{truncateString(value, 20)}</Link>);
      }
    } 
    if (field.linkFk && makeLink) {
      const sectionToLink = field.linkFkTo || field.key.replace("_id", "");
      dataToShow = (
        <Link to={`/${sectionToLink}/detail/${value}`}>
          {dataToShow}
        </Link>
      );
      if (field.popOver && dataToShow) {
        dataToShow = (
          <div style={{ flexDirection: 'row', display: 'flex' }}>
            <div style={{ display: 'flex', marginRight: '8px' }}>{dataToShow}</div>
            <div style={{ display: 'flex' }}>
              <HoverablePreviewTrigger section={sectionToLink} id={value} />
            </div>
          </div>
        );
        
      }
    }
  
    
    return dataToShow;
  }
  else if (field.selectLocalValue === "BOOLEAN_SELECT_OPTIONS") {
    return "No";
  } 

  };


  export  const getDisplayValueWithFullModelData = (modelData, fieldToFind, value, selectMappings, makeLink=false) => {
  const field = modelData?.fields?.find(field => field.key === fieldToFind);
  
  if (field) {
    return getDisplayValue(field, value, selectMappings, makeLink);
  } else {
    // Handle the case where field is not found
    console.error('Field not found');
  }
  };


  export const formattedTodaysDate = () => {
    const date = new Date();
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };


  
/*
  export const truncateString = (str, num) => {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  }*/


  export const truncateString = (str, num) => {
    if (!str || typeof str !== 'string') {
      return ''; 
    }
    return str.length > num ? str.slice(0, num) + "..." : str;
  };
  

  export const priceFormatting = (price, currency, country="fr-FR", showCents) => {
    const fractionDigit = showCents ? 2 : 0;
    let priceToReturn = new Intl.NumberFormat(country, {
      style: 'currency',
      currency: currency,
      maximumFractionDigits: fractionDigit
    }).format(price);

  priceToReturn = priceToReturn.replace(/\u00A0/g, ' ');
  priceToReturn = priceToReturn.replace(/\u202F/g, ' ');
  //     return priceToReturn+' '+currency;
    return priceToReturn;
  };


export const formatDateByLanguage = (date, language, format = "YYYY-MM-DD") => {
  // Set the locale based on the language parameter
  if (language === "fr") {
    dayjs.locale('fr'); // Set to French
    format = "[le] D MMMM YYYY"; // Default format for French if not specified
  } else {
    dayjs.locale('en'); // Default to English
    format = "MMMM D, YYYY";
  }
  return dayjs(date).format(format);
};


export const addDimensionsDepthX = (depth) => {
  let newDepth="";
  if(depth>0){
    newDepth = " x "+depth;
  }
  return newDepth;
};


export const addSeparatorIfNotNull= (string, separator=" - ") => {
  let newString="";
  if(string!=null && string!="" && string!=0){
    newString = separator+string;
  }
  return newString;
};


export const unslugify = (value) => {
  let beautifulValue;
  if(value){
    const valueSpace = value.replace(/_/g, " ");
    beautifulValue = valueSpace.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}
  return beautifulValue;
};


export const parseHtmlToTextArray = (htmlString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  return Array.from(doc.body.childNodes).map((node) => node.textContent);
};


// src/utils/utils.js

export const getSelectValue = (fieldKey, formDataValue, selectLocalValue) => {
  if (selectLocalValue === "BOOLEAN_SELECT_OPTIONS" && Number(formDataValue) !== 1) {
    return "0";
  }
  return formDataValue;
};


export const getColorForSection = (section) => {
  const colorMap = {
    'accounts': '#1976D2',
    'acquisitions': '#D32F2F',
    'addresses': '#4CAF50',
    'all_artists': '#FF9800',
    'artists': '#9C27B0',
    'artpartners': '#009688',
    'artworks': '#E91E63',
    'assets': '#795548',
    'attached_documents': '#E91E63',
    'attached_images': '#607D8B',
    'banks': '#03A9F4',
    'contacts_categories': '#8BC34A',
    'contacts': '#FF5722',
    'conventions': '#673AB7',
    'crates': '#00BCD4',
    'deliveries': '#CDDC39',
    'dimensions_artworks': '#FFC107',
    'emails': '#3F51B5',
    'employees': '#F44336',
    'entities': '#9E9E9E',
    'establishments': '#26A69A', // Ajout de nouvelles couleurs
    'exhibitions': '#8D6E63',
    'extra_costs': '#64B5F6',
    'fairs_franchises': '#AED581',
    'fairs_outposts': '#FFD54F',
    'fairs': '#BA68C8',
    'final_invoices_entities_1.js': '#4DD0E1',
    'final_invoices_entities': '#FF8A65',
    'financials': '#A1887F',
    'fleet': '#90CAF9',
    'galleries_daily_reports': '#C8E6C9',
    'history': '#FFF59D',
    'hours_by_day': '#CE93D8',
    'hours_model': '#80DEEA',
    'hours_specific_date': '#FFAB91',
    'incoming_consignments': '#BCAAA4',
    'incoming_payments': '#BBDEFB',
    'institutions_shows': '#DCEDC8',
    'institutions': '#FFF9C4',
    'journalists': '#D1C4E9',
    'keywords': '#B2EBF2',
    'locations': '#FFE0B2',
    'long_text_change': '#E1BEE7',
    'medias': '#B2DFDB',
    'narratives_artworks.js': '#FFE082',
    'narratives_assets.js': '#D1C4E9',
    'narratives_fleet.js': '#B2DFDB',
    'news': '#A5D6A7',
    'offsite_places': '#FFCC80',
    'outgoing_consignments': '#C5CAE9',
    'outgoing_payments': '#80CBC4',
    'permissions': '#FFB74D',
    'phones': '#9575CD',
    'photographers': '#81D4FA',
    'proforma': '#E6EE9C',
    'publications': '#F06292',
    'publishing_collections': '#9FA8DA',
    'publishing_companies': '#A7FFEB',
    'reservations': '#FFB300',
    'roles': '#7E57C2',
    'sales': '#039BE5',
    'selected_press': '#80DEEA',
    'sets_of_artpartners.js': '#E57373',
    'sets_of_artworks.js': '#4DB6AC',
    'sets_of_assets.js': '#FFCA28',
    'sets_of_contacts.js': '#66BB6A',
    'sets_of_crates.js': '#42A5F5',
    'sets': '#EC407A',
    'shippers': '#9CCC65',
    'single_visits_reports': '#AB47BC',
    'special_messages': '#29B6F6',
    'texts_artists': '#D4E157',
    'texts_artworks': '#FFA726',
    'users': '#5C6BC0',
    'vendors': '#8D6E63',
    'videos': '#26A69A',
    'website_configs': '#A1887F',

  };

  return colorMap[section] || '#bdbdbd';
};

