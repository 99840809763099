import React, { useState, useEffect, useMemo } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { loadItems } from "../../api";
import { useParams } from "react-router-dom";
import SelectableTable from "../Table/SelectableTable";
import useModelLoader from "../../hooks/useModelLoader";
import { usePermissions } from '../../contexts/PermissionsContext';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import { useSelectOptions } from '../../hooks/useSelectOptions';
import { Autocomplete,FormControl, InputLabel, Button, Card, Radio, RadioGroup, CardContent, Chip, InputAdornment, TextField, Checkbox, FormControlLabel, Select, MenuItem} from "@mui/material";
import { makeStyles } from '@mui/styles';
import { unslugify, getColorForSection } from "../../utils/utils";
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import AddIcon from '@mui/icons-material/Add';
import AddModal from '../Modal/AddModal';
import { useSearchParams } from 'react-router-dom';

import GeneralHelpModal from "../Help/GeneralHelpModal";

function TableView() {
  const { section, customSelect, defaultQuery} = useParams();

  const [searchParams] = useSearchParams();
  const searchTermParam = searchParams.get('search');


  const queryClient = useQueryClient();
  const { modelData } = useModelLoader(section);

  const [limit, setLimit] = useState(10); // State for limit
  const [searchInput, setSearchInput] = useState(""); // State for the search input field
  const [searchTerm, setSearchTerm] = useState(); // Actual search term used in the query
  const [splitSpace, setSplitSpace] = useState("No_Split"); 
  const [operator, setOperator] = useState('and');
  const [searchOnlyInIds, setSearchOnlyInIds] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [customFields, setCustomFields] = useState("");


  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [modalSection, setModalSection] = useState(null);

  const openAddModal = (section) => {
    setModalSection(section);
    setAddModalOpen(true);
  };

  const closeAddModal = () => {
    setModalSection(null);
    setAddModalOpen(false);
  };




const beautifulDefaultTitle = unslugify(section);
const sectionColor = getColorForSection(section);

  const searchField = useMemo(() => {
    if (customSelect) {
      return modelData?.fields.find(field => field.key === customSelect);
    }
    return modelData?.fields.find(field => field.showInSearchBar);
  }, [modelData, customSelect]);

  const fields = useMemo(() => {

    return searchField ? [searchField] : [];
  }, [searchField]);

  const selectMappingKey = searchField?.key;
  const selectMappings = useSelectOptions(fields);
  const selectMapping = selectMappings[selectMappingKey];
  
  

  const handleChange = (event) => {
    const selectedId = event.target.value;
    setSelectedValue(selectedId);
  
    if (selectedId) {
      setCustomFields(`${selectMappingKey},${selectedId}`);
    } else {
      setCustomFields(null);
    }
  
    queryClient.invalidateQueries(["items", section, queryparams, searchTerm, limit, fieldsToFetch, fieldsToSearch]);
  };


  useEffect(() => {
    setCustomFields("");
    setSelectedValue("");
    setSearchInput("");
    setSearchTerm(searchTermParam || "");
    setLimit(50);
    setSearchOnlyInIds(false); 
  }, [section, customSelect, searchTermParam]);


  const getKeysOfFieldsToShow = (modelData) => {
    return modelData?.fields
      .filter((field) => field.showInTable || field.showOnlyInMainTable)
      .map((field) => {
        if (field.key === "fk_column" && section?.startsWith("history_")) {
          return section.replace("history_", "") + "_id";
        }
        return field.key;
      });
  };
  


  const getKeysOfFieldsToSearch = (modelData) => {
    // When searchOnlyInIds is true, search only by the ID field
    if (searchOnlyInIds) {
      return ['id']; // Assuming 'id' is the field you use for IDs
    }
    // Otherwise, use the original logic
    return modelData?.fields
      .filter((field) => field.searchInQuery)
      .map((field) => field.key);
      
  };

  let queryparams = { limit: limit, search: searchTerm, split_spaces: splitSpace, condition_operator: operator, custom_field: customFields || ""};

  if (modelData?.modelparams?.order_by) {
    queryparams.order_by = modelData?.modelparams.order_by;
  }
  const fieldsToFetch = useMemo(
    () => getKeysOfFieldsToShow(modelData),
    [modelData]
  );


  const fieldsToSearch = useMemo(
    () => getKeysOfFieldsToSearch(modelData),
    [modelData, searchOnlyInIds] // Add searchOnlyInIds as a dependency
  );

  const query = useQuery({
    queryKey: ["items", section, queryparams, fieldsToFetch, fieldsToSearch],
    queryFn: () => loadItems(section, queryparams, fieldsToFetch, fieldsToSearch),
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: true,
    enabled: !!section 
  });
  
  useEffect(() => {
    // Invalidate and reset query when section changes
    queryClient.removeQueries(["items", section]);
    queryClient.resetQueries(["items", section, queryparams, fieldsToFetch, fieldsToSearch]);
  }, [section, queryClient]);

  
  const items = query.data || [];

  const handleSearchInputChange = (event) => {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/(\r\n|\n|\r)/gm, " ");
    inputValue = inputValue.replace(/\s\s+/g, ' ');
    setSearchInput(inputValue);
  };
  

  const handleSearchOnlyInIdsChange = (event) => {
    setSplitSpace("Split")
    setSearchOnlyInIds(event.target.checked);
  };

  const handleSplitChange = () => {
    setSplitSpace(prev => prev === "No_Split" ? "Split" : "No_Split");
  };



  const handleSearch = (event) => {
    event.preventDefault(); // Prevent the default form submission action

    if (splitSpace === 'Split') {
        // Combine new input with existing search terms, split into individual terms,
        // remove duplicates, and then join back into a single string
        const allTerms = (searchTerm + ' ' + searchInput).trim().split(' ');
        const uniqueTerms = Array.from(new Set(allTerms)).join(' ');
        setSearchTerm(uniqueTerms.trim());
    } else {
        // If splitSpace is not 'Split', add the new input as is, but only if it's not empty
        // and it's not a duplicate of the existing searchTerm.
        if (searchInput && searchInput !== searchTerm) {
            setSearchTerm(searchTerm.trim() ? searchTerm + ' ' + searchInput : searchInput);
        }
    }

    setSearchInput(""); // Clear the input field after updating the searchTerm
    queryClient.invalidateQueries(["items", section, queryparams, searchTerm, limit, fieldsToFetch, fieldsToSearch]);
};


  
  const handleSeeAllClick = () => {
    setSearchTerm(""); // Reset search term
    setSearchInput(""); 
    setLimit(15000); // Set limit for 'See All Records'
    queryClient.invalidateQueries(["items", section, searchTerm, fieldsToFetch]);
  };
  
  useEffect(() => {
    // Invalidate queries when the section changes
    queryClient.invalidateQueries(["items", section, queryparams, searchTerm, limit, fieldsToFetch, fieldsToSearch]);
  }, [section, queryClient, queryparams, searchTerm, fieldsToFetch]);
  
  const handleDeleteSearchTerm = () => {
    setSearchTerm(""); // Reset search term
    setSearchInput(""); // Also clear the search input field
    setLimit(10); // Reset limit to default
  };

  const handleDeleteWord = (index) => {
    const words = searchTerm.split(' ');
    words.splice(index, 1);
    setSearchTerm(words.join(' '));
    setSearchInput(words.join(' '));
  };

  const handleClearAll = () => {
    setSearchTerm(""); // Clear the search terms
    setSearchInput(""); // Clear the input field
    queryClient.invalidateQueries(["items", section, queryparams, searchTerm, limit, fieldsToFetch, fieldsToSearch]);
};


  
  const renderSearchAdornment = () => {
    if (!searchTerm) return null;
  
    if (splitSpace==='Split') {
      return (
        <InputAdornment position="end">
          <div> {/* Updated this line */}
          {searchTerm.split(' ').map((word, index) => (
            <Chip
              key={index}
              label={word}
              onDelete={() => handleDeleteWord(index)}
              size="small"
              color="primary"
              style={{ marginRight: '5px' }}
            />
          ))}
           </div>
        </InputAdornment>
      );
    }
  


    return (
      <InputAdornment position="end">
        <Chip
          label={searchTerm}
          onDelete={handleDeleteSearchTerm}
          size="small"
          color="primary"
        />
      </InputAdornment>
    );
  };
  
  document.title = `List of ${modelData?.appTexts?.tableTitle || beautifulDefaultTitle}`;

  
  const { permissions } = usePermissions();


  let canView =  false;
  let canModify =  false;
  let canViewGlobal =  false;
  let canViewDetail =  false;


  if(modelData?.permissions?.permissions_to_view){
     canView = permissions.includes('can_view_'+section);
     canModify= permissions.includes('can_modify_'+section);
     canViewGlobal = permissions.includes(modelData?.permissions?.permissions_to_view_global);
    if(!canView && !canViewGlobal && !canModify ){
      return (<div><br/>You need permissions for this section</div>);
    }
  }


  let showLink = true;

  if (modelData?.permissions?.permissions_to_view_detail) {
      const requiredPermissionViewDetail = `can_view_detail_${section}`;
      canViewDetail = permissions.includes(requiredPermissionViewDetail);
      const requiredPermissionModify = `can_modify_${section}`;
      canModify = permissions.includes(requiredPermissionModify);
      showLink = canViewDetail || canModify; 
  }
  


  return (
    
    <div>
        {isAddModalOpen && (
    <AddModal open={isAddModalOpen} handleClose={closeAddModal} section={modalSection} />
  )}
      <div style={{ display: "flex", alignItems: "center", marginBottom: 10 }}>
      <span
      style={{
        marginRight: 10,
        display: "inline-block",
        width: "6px", // Taille du point
        height: "16px", // Taille du point
        //borderRadius: "50%", // Pour en faire un cercle
        backgroundColor: sectionColor, // Couleur dynamique
        marginRight: "8px", // Espace entre le point et le texte
      }}
    ></span>
      <div className="text-color" style={{ fontSize: "16pt", textTransform: "capitalize", marginRight: 10, display: 'flex', alignItems: 'center' }}>
  {modelData?.appTexts?.tableTitle || beautifulDefaultTitle}

</div>
        <GeneralHelpModal section={section} view="table" modelData={modelData}/>
        
        {modelData?.noAddButton!=true && ( <Tooltip title="Add Record">
      <IconButton
        color="primary"
        onClick={() => openAddModal(section)}
        size="small"
        aria-label="add record"
 
        onMouseOver={(e) => (e.currentTarget.style.opacity = 1)}
        onMouseOut={(e) => (e.currentTarget.style.opacity = 0.7)}
      >
        <AddIcon />
      </IconButton>
    </Tooltip>)}
      </div>

      <Card style={{ margin: 0, marginBottom: 10}}>
        <CardContent style={{ paddingTop: 0, paddingBottom: 2}}>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
      <form onSubmit={handleSearch} style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <TextField
  id="standard-basic"
  label="Search"
  variant="standard"
  value={searchInput}
  onChange={handleSearchInputChange}
  style={{ width: '40vw', flexGrow: 1}}
  InputProps={{
    style: {
      overflowX: 'auto',
      overflowY: 'hidden',
    },
    endAdornment: (
      <InputAdornment position="end">
        {renderSearchAdornment()}
        <IconButton onClick={handleClearAll}>
          <ClearIcon />
        </IconButton>
      </InputAdornment>
    ),
  }}
  inputProps={{
    style: {
      whiteSpace: 'nowrap',
      overflowX: 'auto',
    },
  }}
/>

<FormControl>
{selectMapping && (
  <Autocomplete
    
    id={`${selectMappingKey}-autocomplete`}
    options={Object.entries(selectMapping).map(([id, name]) => ({ id, label: name }))}
    getOptionLabel={(option) => option.label}
    style={{ width: '20vw', margin: 15 }}
    renderInput={(params) => <TextField {...params} label={searchField?.title} variant="standard"/>}
    value={selectedValue ? { id: selectedValue, label: selectMapping[selectedValue] } : null}
    onChange={(event, newValue) => {
      handleChange({ target: { value: newValue ? newValue.id : '' } });
    }}
  />
)}

</FormControl>


  <Button type="submit" variant="outlined" style={{ minWidth: "20vw"}} >Search</Button>

      </form>
      </div>
      <div style={{ display: 'flex', justifyContent: 'justify-start', width: '100%', marginTop:5}}>
        
  <FormControlLabel
     //classes={{ root: classes.checkbox }} // apply the custom styles
  control={
    <Checkbox
    disabled={searchOnlyInIds}
      checked={splitSpace === "Split"}
      onChange={handleSplitChange}
      inputProps={{ 'aria-label': 'controlled' }}
    />
  }
  label="Search Terms Separately"
/>

{(!searchOnlyInIds && splitSpace === "Split") && (
  <FormControl component="fieldset" style={{ marginLeft: '10px' }}>
    <RadioGroup
      row
      aria-labelledby="operator-radio-group-label"
      name="operator-radio-group"
      value={operator}
      onChange={(event) => setOperator(event.target.value)}
    >
      <FormControlLabel value="and" control={<Radio />} label="AND" />
      <FormControlLabel value="or" control={<Radio />} label="OR" />
    </RadioGroup>
  </FormControl>
)}




<FormControlLabel
       //   classes={{ root: classes.checkbox }} // apply the custom styles
        control={
          <Checkbox
            checked={searchOnlyInIds}
            onChange={handleSearchOnlyInIdsChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        }
        label="Search Only IDs"
      />


<Button  color={items?.length===15000 ? "error" : "primary"} onClick={handleSeeAllClick}>See All Records in {unslugify(section)} 



<Tooltip title="This will show 15 000 records that were last modified, for some section this not the entire records present in the Database. But for performance reasons we have to limit to 15 000, so if you need a very specific filtering, use first the search box above and works with sets to complete your selection">
   <InfoIcon sx={{cursor: 'help'}}/>
    </Tooltip>
    </Button>


{customFields!="" && items?.length===10000 && (
    <Tooltip title="For performance reason search is limited to 10 000. Use multiple search and add to set for very specific filtering">
   <Button sx={{cursor: 'help'}} color="error" type="button">Max Number reached<InfoIcon type="button" color="error"/></Button>
    </Tooltip>)}
       </div> </CardContent></Card>

      <div>
        {items.length > 0 ? (
          
          <SelectableTable
            checkboxSelection
            data={items}
            modeldata={modelData}
            section={section}
            showLink={showLink}
          />
        ): query.message!='' ? (<div>{query.message}</div>):null}
      </div>
    </div>




    
  );
}

export default TableView;
